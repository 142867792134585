import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NotificationDeliveryApi } from '@/apis';
import { PAGE_SIZE } from '@/config';
import { PaginatedList } from '@/libs/paginated-list';
import { NotificationDelivery } from '@/models';
import { AppState } from '@/store';
import {
  SetNotificationDeliveries,
  ConcatNotificationDeliveries,
  SetNotificationDelivery,
  DeleteNotificationDelivery,
} from '@/store/notification-delivery.store';

@Injectable()
export class NotificationDeliveryService {
  constructor(private store: Store<AppState>, private api: NotificationDeliveryApi) {}
  notificationDeliveryPage: PaginatedList<NotificationDelivery> = new PaginatedList<NotificationDelivery>(PAGE_SIZE);

  fetchNotificationDeliveries(isRefresh: boolean = true): Observable<NotificationDelivery[]> {
    const page = isRefresh ? 1 : this.notificationDeliveryPage.page + 1;
    return this.api.fetchNotificationDeliverys(page, PAGE_SIZE).pipe(
      tap((notificationDeliveries) => {
        if (isRefresh) {
          this.store.dispatch(new SetNotificationDeliveries(notificationDeliveries));
        } else {
          this.store.dispatch(new ConcatNotificationDeliveries(notificationDeliveries));
        }
        this.notificationDeliveryPage.page = page;
        this.notificationDeliveryPage.setPage(notificationDeliveries);
      })
    );
  }

  fetchNotificationDelivery(id: string): Observable<NotificationDelivery> {
    return this.api
      .fetchNotificationDelivery(id)
      .pipe(tap((notificationDelivery) => this.store.dispatch(new SetNotificationDelivery(notificationDelivery))));
  }

  createNotificationDelivery(body: any): Observable<NotificationDelivery> {
    return this.api
      .createNotificationDelivery(body)
      .pipe(tap((notificationDelivery) => this.store.dispatch(new SetNotificationDelivery(notificationDelivery))));
  }

  updateNotificationDelivery(id: string, params: any): Observable<NotificationDelivery> {
    return this.api
      .updateNotificationDelivery(id, params)
      .pipe(tap((notificationDelivery) => this.store.dispatch(new SetNotificationDelivery(notificationDelivery))));
  }

  deleteNotificationDelivery(id: string): Observable<any> {
    return this.api
      .deleteNotificationDelivery(id)
      .pipe(tap(() => this.store.dispatch(new DeleteNotificationDelivery(id))));
  }
}
