import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SpotAlbum } from '@/models';

@Injectable()
export class SpotAlbumApi {
  constructor(private http: HttpClient) {}

  fetchSpotAlbums(albumId: string): Observable<SpotAlbum[]> {
    return this.http.get<SpotAlbum[]>(`albums/${albumId}/spot_albums`);
  }

  createSpotAlbum(spotId: string, params: any): Observable<SpotAlbum> {
    return this.http.post<SpotAlbum>(`spots/${spotId}/spot_albums`, params);
  }

  //   updateSpotAlbum(spotId: string, id: string, params: any): Observable<SpotAlbum> {
  //     return this.http.put<SpotAlbum>(`spots/${spotId}/spot_albums/${id}`, params);
  //   }

  deleteSpotAlbum(spotId: string, id: string): Observable<any> {
    return this.http.delete<any>(`spots/${spotId}/spot_albums/${id}`);
  }
}
