import { Action, createSelector, createFeatureSelector } from '@ngrx/store';
import * as firebase from 'firebase/app';

export const SET_FIREBASE_USER = '[Session] Set firebase user';
export const REMOVE_SESSION = '[Session] Remove session';

export class SetFirebaseUser implements Action {
  readonly type = SET_FIREBASE_USER;
  constructor(public user: firebase.User) {}
}
export class RemoveSession implements Action {
  readonly type = REMOVE_SESSION;
  constructor() {}
}
export type Actions = SetFirebaseUser | RemoveSession;

export interface State {
  firebaseUser?: firebase.User;
}
export const initialState: State = {
  // NOTE: undefined === 初期値, null === 認証切れ
  firebaseUser: undefined,
};

export function reducer(state: State = initialState, action: Actions): State {
  switch (action.type) {
    case SET_FIREBASE_USER:
      return { ...state, firebaseUser: action.user };
    default:
      return state;
  }
}

export const selectFeature = createFeatureSelector<State>('session');
export const getFirebaseUser = createSelector(selectFeature, (state: State) => state.firebaseUser);
