import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Comment } from '@/models';

@Injectable()
export class CommentApi {
  constructor(private http: HttpClient) {}

  fetchComments(albumId: string): Observable<Comment[]> {
    return this.http.get<Comment[]>(`albums/${albumId}/comments`);
  }

  fetchComment(id: string): Observable<Comment> {
    return this.http.get<Comment>(`comments/${id}`);
  }

  createComment(albumId: string, params: any): Observable<Comment> {
    return this.http.post<Comment>(`albums/${albumId}/comments`, params);
  }

  updateComment(id: string, params: any): Observable<Comment> {
    return this.http.put<Comment>(`comments/${id}`, params);
  }

  deleteComment(id: string): Observable<any> {
    return this.http.delete<any>(`comments/${id}`);
  }

  reorderComments(albumId: string, params: any): Observable<any> {
    return this.http.put<any>(`albums/${albumId}/comments/reorder`, params);
  }
}
