import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MapMarker } from '@/models';

@Injectable()
export class MapMarkerApi {
  constructor(private http: HttpClient) {}

  fetchMapMarkers(floorMapId: string): Observable<MapMarker[]> {
    return this.http.get<MapMarker[]>(`floor_maps/${floorMapId}/map_markers`);
  }

  fetchMapMarker(id: string): Observable<MapMarker> {
    return this.http.get<MapMarker>(`map_markers/${id}`);
  }

  createMapMarker(floorMapId: string, params: any): Observable<MapMarker> {
    return this.http.post<MapMarker>(`floor_maps/${floorMapId}/map_markers`, params);
  }

  updateMapMarker(id: string, params: any): Observable<MapMarker> {
    return this.http.put<MapMarker>(`map_markers/${id}`, params);
  }

  deleteMapMarker(id: string): Observable<any> {
    return this.http.delete<any>(`map_markers/${id}`);
  }
}
