// Meta Reducers
import { ActionReducer, MetaReducer } from '@ngrx/store';

import { REMOVE_SESSION } from '@/store/session.store';

export function resetState(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === REMOVE_SESSION) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
export const metaReducers: MetaReducer<any>[] = [resetState];
