import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NotificationDelivery } from '@/models';

@Injectable()
export class NotificationDeliveryApi {
  constructor(private http: HttpClient) {}

  fetchNotificationDeliverys(page: number = 1, perPage: number = 20): Observable<NotificationDelivery[]> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http.get<NotificationDelivery[]>('notification_deliveries', { params });
  }

  fetchNotificationDelivery(id: string): Observable<NotificationDelivery> {
    return this.http.get<NotificationDelivery>(`notification_deliveries/${id}`);
  }

  createNotificationDelivery(params: any): Observable<NotificationDelivery> {
    return this.http.post<NotificationDelivery>('notification_deliveries', params);
  }

  updateNotificationDelivery(id: string, params: any): Observable<NotificationDelivery> {
    return this.http.put<NotificationDelivery>(`notification_deliveries/${id}`, params);
  }

  deleteNotificationDelivery(id: string): Observable<any> {
    return this.http.delete<any>(`notification_deliveries/${id}`);
  }
}
