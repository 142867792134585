export class PaginatedList<T> {
  readonly pageSize: number;
  page: number;
  last: T[];

  constructor(pageSize: number) {
    this.page = 0;
    this.pageSize = pageSize;
  }

  get isComplete(): boolean {
    return this.last && (this.last.length % this.pageSize !== 0 || this.last.length === 0);
  }
  setPage(items: T[]) {
    this.last = items;
  }
}
