import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EditorImageApi } from '@/apis';

import { UploadService } from './upload.service';

@Injectable()
export class EditorImageService {
  constructor(private api: EditorImageApi, private uploadService: UploadService) {}

  uploadEditorImage(file: File): Observable<any> {
    if (!file) {
      return;
    }

    const policy$ = this.api.issuePolicy(file.name, file.size, file.type);
    return this.uploadService.uploadImageUsingPolicy(file, policy$);
  }
}
