import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FlyerApi } from '@/apis';
import { PAGE_SIZE } from '@/config';
import { PaginatedList } from '@/libs/paginated-list';
import { Flyer } from '@/models';
import { UploadService } from '@/services/upload.service';
import { AppState } from '@/store';
import {
  SetFlyers,
  ConcatFlyers,
  SetAlbumFlyers,
  ConcatAlbumFlyers,
  SetFlyer,
  CreateFlyer,
  DeleteFlyer,
} from '@/store/flyer.store';

@Injectable()
export class FlyerService {
  constructor(private store: Store<AppState>, private api: FlyerApi, private uploadService: UploadService) {}
  flyerPage: PaginatedList<Flyer> = new PaginatedList<Flyer>(PAGE_SIZE);

  fetchFlyers(isRefresh: boolean = true): Observable<Flyer[]> {
    const page = isRefresh ? 1 : this.flyerPage.page + 1;
    return this.api.fetchFlyers(page, PAGE_SIZE).pipe(
      tap((flyers) => {
        if (isRefresh) {
          this.store.dispatch(new SetFlyers(flyers));
        } else {
          this.store.dispatch(new ConcatFlyers(flyers));
        }
        this.flyerPage.page = page;
        this.flyerPage.setPage(flyers);
      })
    );
  }

  fetchAlbumFlyers(albumId: string, isRefresh: boolean = true): Observable<Flyer[]> {
    const page = isRefresh ? 1 : this.flyerPage.page + 1;
    return this.api.fetchAlbumFlyers(albumId, page, PAGE_SIZE).pipe(
      tap((flyers) => {
        if (isRefresh) {
          this.store.dispatch(new SetAlbumFlyers(albumId, flyers));
        } else {
          this.store.dispatch(new ConcatAlbumFlyers(albumId, flyers));
        }
        this.flyerPage.page = page;
        this.flyerPage.setPage(flyers);
      })
    );
  }

  fetchFlyer(id: string): Observable<Flyer> {
    return this.api.fetchFlyer(id).pipe(tap((flyer) => this.store.dispatch(new SetFlyer(flyer))));
  }

  createFlyer(body: any): Observable<Flyer> {
    return this.api.createFlyer(body).pipe(tap((flyer) => this.store.dispatch(new CreateFlyer(flyer))));
  }

  updateFlyer(id: string, params: any): Observable<Flyer> {
    return this.api.updateFlyer(id, params).pipe(tap((flyer) => this.store.dispatch(new SetFlyer(flyer))));
  }

  deleteFlyer(id: string): Observable<any> {
    return this.api.deleteFlyer(id).pipe(tap(() => this.store.dispatch(new DeleteFlyer(id))));
  }

  searchFlyers(params: any): Observable<Flyer[]> {
    return this.api.searchFlyers(params).pipe(tap((flyers) => this.store.dispatch(new SetFlyers(flyers))));
  }

  uploadImage(file: File): Observable<any> {
    if (!file) {
      return;
    }

    let policy$;
    if (file.type === 'application/pdf') {
      policy$ = this.api.issuePdfPolicy(file.name, file.size, file.type);
    } else {
      policy$ = this.api.issuePolicy(file.name, file.size, file.type);
    }
    return this.uploadService.uploadImageUsingPolicy(file, policy$);
  }
}
