import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PopupApi } from '@/apis';
import { Popup } from '@/models';
import { AppState } from '@/store';
import { SetTrackPopups, SetPopups, CreatePopup, DeletePopup } from '@/store/popup.store';

import { UploadService } from './upload.service';

@Injectable()
export class PopupService {
  constructor(private store: Store<AppState>, private api: PopupApi, private uploadService: UploadService) {}

  fetchTrackPopups(trackId: string): Observable<Popup[]> {
    return this.api
      .fetchTrackPopups(trackId)
      .pipe(tap((popups) => this.store.dispatch(new SetTrackPopups(trackId, popups))));
  }

  fetchAllPopups(albumId: string): Observable<Popup[]> {
    return this.api.fetchAllPopups(albumId).pipe(tap((popups) => this.store.dispatch(new SetPopups(popups))));
  }

  createPopup(trackId: string, body: any): Observable<Popup> {
    return this.api
      .createPopup(trackId, body)
      .pipe(tap((popup) => this.store.dispatch(new CreatePopup(trackId, popup))));
  }

  deletePopup(trackId: string, id: string): Observable<any> {
    return this.api.deletePopup(id).pipe(tap(() => this.store.dispatch(new DeletePopup(trackId, id))));
  }

  uploadImage(file: File): Observable<any> {
    if (!file) {
      return;
    }

    const policy$ = this.api.issuePolicy(file.name, file.size, file.type);
    return this.uploadService.uploadImageUsingPolicy(file, policy$);
  }
}
