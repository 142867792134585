import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FloorMap } from '@/models';

export const SET_FLOOR_MAPS = '[FloorMap] SetFloorMaps';
export const SET_ALBUM_FLOOR_MAPS = '[FloorMap] SetAlbumFloorMaps';
export const SET_FLOOR_MAP = '[FloorMap] SetFloorMap';
export const DELETE_FLOOR_MAP = '[FloorMap] DeleteFloorMap';

export class SetFloorMaps implements Action {
  readonly type = SET_FLOOR_MAPS;
  constructor(public floorMaps: FloorMap[]) {}
}
export class SetAlbumFloorMaps implements Action {
  readonly type = SET_ALBUM_FLOOR_MAPS;
  constructor(public albumId: string, public floorMaps: FloorMap[]) {}
}
export class SetFloorMap implements Action {
  readonly type = SET_FLOOR_MAP;
  constructor(public floorMap: FloorMap) {}
}
export class DeleteFloorMap implements Action {
  readonly type = DELETE_FLOOR_MAP;
  constructor(public floorMapId: string) {}
}

export type All = SetFloorMaps | SetAlbumFloorMaps | SetFloorMap | DeleteFloorMap;

export interface State {
  floorMaps: { [id: string]: FloorMap };
  floorMapIds: string[];
  albumFloorMapIds: { [albumId: string]: string[] };
}
export const initialState: State = {
  floorMaps: {},
  floorMapIds: [],
  albumFloorMapIds: {},
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case SET_FLOOR_MAPS: {
      const ids = action.floorMaps.map((floorMap) => floorMap.id);
      const floorMaps = { ...state.floorMaps };
      action.floorMaps.forEach((floorMap) => {
        floorMaps[floorMap.id] = floorMap;
      });
      return { ...state, floorMapIds: ids, floorMaps };
    }
    case SET_ALBUM_FLOOR_MAPS: {
      const ids = action.floorMaps.map((floorMap) => floorMap.id);
      const floorMaps = { ...state.floorMaps };
      const albumFloorMapIds = { ...state.albumFloorMapIds, [action.albumId]: ids };
      action.floorMaps.forEach((floorMap) => {
        floorMaps[floorMap.id] = floorMap;
      });
      return { ...state, albumFloorMapIds, floorMaps };
    }
    case SET_FLOOR_MAP: {
      const floorMap = action.floorMap;
      const floorMaps = state.floorMaps;
      floorMaps[floorMap.id] = floorMap;
      const ids = floorMaps[floorMap.id] ? state.floorMapIds : [].concat(state.floorMapIds, floorMap.id);
      return { ...state, floorMaps, floorMapIds: ids };
    }
    case DELETE_FLOOR_MAP: {
      const ids = state.floorMapIds.filter((id) => id !== action.floorMapId);
      const floorMaps = state.floorMaps;
      delete floorMaps[action.floorMapId];
      return { ...state, floorMaps, floorMapIds: ids };
    }
    default: {
      return state;
    }
  }
}

export const selectFeature = createFeatureSelector<State>('floorMap');

export const getFloorMap = (id: string) => {
  return createSelector(selectFeature, (state: State) => state.floorMaps[id]);
};

export const getFloorMaps = createSelector(selectFeature, (state: State) => {
  // if (!state.floorMapIds) { return null; }
  return state.floorMapIds.map((id) => state.floorMaps[id]);
});

export const getAlbumFloorMaps = (albumId: string) => {
  return createSelector(selectFeature, (state: State) => {
    return state.albumFloorMapIds[albumId] ? state.albumFloorMapIds[albumId].map((id) => state.floorMaps[id]) : [];
  });
};
