import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Album } from '@/models';

@Injectable()
export class AlbumApi {
  constructor(private http: HttpClient) {}

  fetchAlbums(page: number = 1, perPage: number = 20): Observable<Album[]> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http.get<Album[]>('albums', { params });
  }

  fetchAlbum(id: string): Observable<Album> {
    return this.http.get<Album>(`albums/${id}`);
  }

  createAlbum(params: any): Observable<Album> {
    return this.http.post<Album>('albums', params, {
      headers: new HttpHeaders().set('x-meta-respect-original-key-name', ''),
    });
  }

  updateAlbum(id: string, params: any): Observable<Album> {
    return this.http.put<Album>(`albums/${id}`, params, {
      headers: new HttpHeaders().set('x-meta-respect-original-key-name', ''),
    });
  }

  deleteAlbum(id: string): Observable<any> {
    return this.http.delete<any>(`albums/${id}`);
  }

  searchAlbums(params: any): Observable<Album[]> {
    return this.http.get<Album[]>('albums/search', { params });
  }

  issuePolicy(originalName: string, size: number, contentType: string): Observable<any> {
    const url = 'albums/publish_policy';
    return this.http.post(
      url,
      { medium: { original_name: originalName, size, content_type: contentType } },
      { headers: new HttpHeaders().set('x-meta-respect-original-key-name', '') }
    );
  }
}
