import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TrackApi } from '@/apis';
import { Track, Album } from '@/models';
import { AppState } from '@/store';
import { SetAlbumTracks } from '@/store/album.store';

import { UploadService } from './upload.service';

@Injectable()
export class TrackService {
  constructor(private store: Store<AppState>, private api: TrackApi, private uploadService: UploadService) {}

  fetchTracks(albumId: string): Observable<Track[]> {
    return this.api
      .fetchTracks(albumId)
      .pipe(tap((tracks) => this.store.dispatch(new SetAlbumTracks(albumId, tracks))));
  }

  updateAlbumTracks(albumId: string, params: any): Observable<Album> {
    return this.api.updateTracks(albumId, params);
  }

  createTrack(albumId: string, file: File): Observable<any> {
    if (!file) {
      return;
    }

    const policy$ = this.api.issuePolicy(albumId, file.name, file.size, file.type);
    return this.uploadService.uploadTrackUsingPolicy(file, policy$);
  }

  createTrackWithoutAudio(albumId: string): Observable<any> {
    return this.api.issuePolicy(albumId, 'track_without_audio', 100, 'audio/wav');
  }
}
