import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FloorMap } from '@/models';

@Injectable()
export class FloorMapApi {
  constructor(private http: HttpClient) {}

  fetchFloorMaps(albumId: string): Observable<FloorMap[]> {
    return this.http.get<FloorMap[]>('floor_maps', { params: { album_id: albumId } });
  }

  fetchFloorMap(id: string): Observable<FloorMap> {
    return this.http.get<FloorMap>(`floor_maps/${id}`);
  }

  createFloorMap(params: any): Observable<FloorMap> {
    return this.http.post<FloorMap>('floor_maps', params);
  }

  updateFloorMap(id: string, params: any): Observable<FloorMap> {
    return this.http.put<FloorMap>(`floor_maps/${id}`, params);
  }

  deleteFloorMap(id: string): Observable<any> {
    return this.http.delete<any>(`floor_maps/${id}`);
  }

  issuePolicy(originalName: string, size: number, contentType: string): Observable<any> {
    const url = 'floor_maps/publish_policy';
    return this.http.post(
      url,
      { medium: { original_name: originalName, size, content_type: contentType } },
      { headers: new HttpHeaders().set('x-meta-respect-original-key-name', '') }
    );
  }
}
