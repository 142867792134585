import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { DiscountTicket } from '@/models';

export const SET_DISCOUNT_TICKET = '[DiscountTicket] SetDiscountTicket';
export const DELETE_DISCOUNT_TICKET = '[DiscountTicket] DeleteDiscountTicket';

export class SetDiscountTicket implements Action {
  readonly type = SET_DISCOUNT_TICKET;
  constructor(public albumId: string, public discountTicket: DiscountTicket) {}
}
export class DeleteDiscountTicket implements Action {
  readonly type = DELETE_DISCOUNT_TICKET;
  constructor(public albumId: string) {}
}

export type All = SetDiscountTicket | DeleteDiscountTicket;

export interface State {
  discountTickets: { [id: string]: DiscountTicket };
  albumIds: string[];
}
export const initialState: State = {
  discountTickets: {},
  albumIds: [],
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case SET_DISCOUNT_TICKET: {
      const discountTicket = action.discountTicket;
      const discountTickets = state.discountTickets;
      discountTickets[action.albumId] = discountTicket;
      const ids = discountTickets[action.albumId] ? state.albumIds : [].concat(state.albumIds, action.albumId);
      return { ...state, discountTickets, albumIds: ids };
    }
    case DELETE_DISCOUNT_TICKET: {
      const ids = state.albumIds.filter((id) => id !== action.albumId);
      const discountTickets = state.discountTickets;
      delete discountTickets[action.albumId];
      return { ...state, discountTickets, albumIds: ids };
    }
    default: {
      return state;
    }
  }
}

export const selectFeature = createFeatureSelector<State>('discountTicket');

export const getDiscountTicket = (albumId: string) => {
  return createSelector(selectFeature, (state: State) => state.discountTickets[albumId]);
};
