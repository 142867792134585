import { HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { UploadApi } from '@/apis';

@Injectable()
export class UploadService {
  constructor(private uploadApi: UploadApi) {}

  uploadImageUsingPolicy(file: File, data$: Observable<any>) {
    return data$.pipe(
      map((data) => {
        const form = new FormData();
        for (const name in data.meta.form) {
          if (Object.prototype.hasOwnProperty.call(data.meta.form, name)) {
            form.append(name, data.meta.form[name]);
          }
        }
        form.append('file', file);
        return { data, form };
      }),
      mergeMap(({ data, form }) =>
        this.uploadApi.uploadToS3(data.meta.upload_url, form).pipe(
          map(() => {
            return {
              id: data.id,
              urls: {
                small: data.presignedUrl,
                medium: data.presignedUrl,
                large: data.presignedUrl,
                original: data.presignedUrl,
              },
            };
          })
        )
      )
    );
  }

  uploadTrackUsingPolicy(file: File, data$: Observable<any>) {
    return data$.pipe(
      map((data) => {
        const form = new FormData();
        for (const name in data.meta.form) {
          if (Object.prototype.hasOwnProperty.call(data.meta.form, name)) {
            form.append(name, data.meta.form[name]);
          }
        }
        form.append('file', file);
        return { data, form };
      }),
      mergeMap(({ data, form }) =>
        this.uploadApi.uploadToS3(data.meta.upload_url, form).pipe(
          map((progress: HttpEventType.UploadProgress) => {
            return {
              progress,
              id: data.id,
            };
          })
        )
      )
    );
  }
}
