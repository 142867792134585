import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Cast } from '@/models';

export const SET_CASTS = '[Cast] SetCasts';
export const CONCAT_CASTS = '[Cast] ConcatCasts';
export const SET_CAST = '[Cast] SetCast';
export const DELETE_CAST = '[Cast] DeleteCast';

export class SetCasts implements Action {
  readonly type = SET_CASTS;
  constructor(public casts: Cast[]) {}
}
export class ConcatCasts implements Action {
  readonly type = CONCAT_CASTS;
  constructor(public casts: Cast[]) {}
}
export class SetCast implements Action {
  readonly type = SET_CAST;
  constructor(public cast: Cast) {}
}
export class DeleteCast implements Action {
  readonly type = DELETE_CAST;
  constructor(public castId: string) {}
}

export type All = SetCasts | ConcatCasts | SetCast | DeleteCast;

export interface State {
  casts: { [id: string]: Cast };
  castIds: string[];
}
export const initialState: State = {
  casts: {},
  castIds: [],
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case SET_CASTS: {
      const ids = action.casts.map((cast) => cast.id);
      const casts = { ...state.casts };
      action.casts.forEach((cast) => {
        casts[cast.id] = cast;
      });
      return { ...state, castIds: ids, casts };
    }
    case CONCAT_CASTS: {
      const ids = action.casts.map((cast) => cast.id);
      const casts = { ...state.casts };
      action.casts.forEach((cast) => {
        casts[cast.id] = cast;
      });
      return { ...state, castIds: state.castIds.concat(ids), casts };
    }
    case SET_CAST: {
      const cast = action.cast;
      const casts = state.casts;
      casts[cast.id] = cast;
      const ids = casts[cast.id] ? state.castIds : [].concat(state.castIds, cast.id);
      return { ...state, casts, castIds: ids };
    }
    case DELETE_CAST: {
      const ids = state.castIds.filter((id) => id !== action.castId);
      const casts = state.casts;
      delete casts[action.castId];
      return { ...state, casts, castIds: ids };
    }
    default: {
      return state;
    }
  }
}

export const selectFeature = createFeatureSelector<State>('cast');

export const getCast = (id: string) => {
  return createSelector(selectFeature, (state: State) => state.casts[id]);
};

export const getCasts = createSelector(selectFeature, (state: State) => {
  return state.castIds.map((id) => state.casts[id]);
});
