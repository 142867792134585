import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController, LoadingController, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Store } from '@ngrx/store';
import * as firebase from 'firebase/app';
import { Observable, from, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UserApi } from '@/apis';
import { AppState } from '@/store';
import { SetFirebaseUser, RemoveSession } from '@/store/session.store';

// import { FirebaseMessagingService } from '@/services/firebase-messaging.service';

@Injectable()
export class SessionService {
  constructor(
    private storage: Storage,
    private store: Store<AppState>,
    private userApi: UserApi, // private firebaseMessagingService: FirebaseMessagingService
    private zone: NgZone,
    private router: Router,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController
  ) {
    firebase.auth().onAuthStateChanged((user) => {
      store.dispatch(new SetFirebaseUser(user));
    });
  }

  async getIdToken(): Promise<string> {
    // NOTE: blacklistedRoutes に入っていても、tokenGetter は呼ばれてしまうので、以下のようにしている
    // ログインが完了するまで、トークンが正しくセットされないことに注意
    return firebase.auth().currentUser ? firebase.auth().currentUser.getIdToken() : null;
  }

  async signInAnonymously() {
    const signin = firebase.auth().signInAnonymously();
    signin.then(() => this.storage.set('registered', true));
    return signin;
  }

  promoteUserWithEmailAndPassword(email: string, password: string): Observable<firebase.User> {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    return from(firebase.auth().currentUser.linkWithCredential(credential)).pipe(
      map((userCred) => userCred.user),
      tap((user: firebase.User) => user.sendEmailVerification())
      // tap((user: firebase.User) => this.userApi.updateEmail(user.email).subscribe())
    );
  }

  registerWithEmailAndPassword(email: string, password: string): Observable<firebase.User> {
    return from(firebase.auth().createUserWithEmailAndPassword(email, password)).pipe(
      map((userCred) => userCred.user),
      tap((user: firebase.User) => user.sendEmailVerification())
      // tap((user: firebase.User) => this.userApi.updateEmail(user.email).subscribe())
    );
  }

  signInWithEmailAndPassword(email: string, password: string): Observable<firebase.User> {
    return from(firebase.auth().signInWithEmailAndPassword(email, password)).pipe(
      map((userCred) => userCred.user),
      tap((user: firebase.User) => this.userApi.updateProfile({ collaborator: { email: user.email } }).subscribe())
    );
  }

  sendPasswordResetEmail(email: string) {
    return from(firebase.auth().sendPasswordResetEmail(email));
  }

  signOut(): Observable<any> {
    return from(firebase.auth().signOut()).pipe(
      tap(() => this.storage.remove('registered')),
      // tap(() => this.firebaseMessagingService.unregister()),
      tap(() => this.store.dispatch(new RemoveSession()))
    );
  }

  async trySignOut() {
    const alert = await this.alertCtrl.create({
      header: 'ログアウトの確認',
      message: 'ログアウトします。よろしいですか?',
      buttons: [
        {
          text: 'キャンセル',
          role: 'cancel',
        },
        {
          text: 'ログアウト',
          handler: async () => {
            const loading = await this.loadingCtrl.create({
              message: 'ログアウトしています',
            });
            await loading.present();

            this.signOut().subscribe(
              async () => {
                const toast = await this.toastCtrl.create({
                  message: 'ログアウトしました',
                  duration: 3000,
                });
                this.zone.run(() => {
                  this.router.navigateByUrl('/auth/login');
                });
                loading.dismiss();
                toast.present();
              },
              async () => {
                const toast = await this.toastCtrl.create({
                  message: 'ログアウトに失敗しました',
                  duration: 3000,
                });
                loading.dismiss();
                toast.present();
              }
            );
          },
        },
      ],
    });
    await alert.present();
  }

  synchronizeUserProfile(firebaseUser: firebase.User) {
    const requests = [];
    if (firebaseUser.displayName && firebaseUser.displayName !== '') {
      requests.push(this.userApi.updateProfile({ name: firebaseUser.displayName }));
    }
    // TODO: 画像のぶっこ抜き
    return forkJoin(requests);
  }

  reauthenticate(provider: string, password?: string): Observable<any> {
    switch (provider) {
      case firebase.auth.EmailAuthProvider.PROVIDER_ID: {
        const credential = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser.email, password);
        return from(firebase.auth().currentUser.reauthenticateWithCredential(credential));
      }
    }
  }
}
