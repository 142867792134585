// Production Environments
import { Environment } from './environment.type';

export const environment: Environment = {
  name: 'production',
  production: true,
  previewHost: '',
  sentryDsn: 'https://ab78b4db839b4b8cabf1888f6b3ed212@o54978.ingest.sentry.io/5384421',

  api: {
    host: 'mimitab-v2.herokuapp.com',
    port: 443,
    ssl: true,
  },

  firebaseConfig: {
    apiKey: 'AIzaSyBvRLpLsO7aTqTwZdDeB6WfCpZdvEVA7sE',
    authDomain: 'mimitab-collaborator-prod.firebaseapp.com',
    databaseURL: 'https://mimitab-collaborator-prod.firebaseio.com',
    projectId: 'mimitab-collaborator-prod',
    storageBucket: 'mimitab-collaborator-prod.appspot.com',
  },
};

export * from './environment.type';
