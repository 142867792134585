import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Flyer } from '@/models';

export const SET_FLYERS = '[Flyer] SetFlyers';
export const CONCAT_FLYERS = '[Flyer] ConcatFlyers';
export const SET_ALBUM_FLYERS = '[Flyer] SetAlbumFlyers';
export const CONCAT_ALBUM_FLYERS = '[Flyer] ConcatAlbumFlyers';
export const SET_FLYER = '[Flyer] SetFlyer';
export const CREATE_FLYER_ON_MODAL = '[Flyer] CreateFlyer';
export const DELETE_FLYER = '[Flyer] DeleteFlyer';

export class SetFlyers implements Action {
  readonly type = SET_FLYERS;
  constructor(public flyers: Flyer[]) {}
}
export class ConcatFlyers implements Action {
  readonly type = CONCAT_FLYERS;
  constructor(public flyers: Flyer[]) {}
}
export class SetAlbumFlyers implements Action {
  readonly type = SET_ALBUM_FLYERS;
  constructor(public albumId: string, public flyers: Flyer[]) {}
}
export class ConcatAlbumFlyers implements Action {
  readonly type = CONCAT_ALBUM_FLYERS;
  constructor(public albumId: string, public flyers: Flyer[]) {}
}
export class SetFlyer implements Action {
  readonly type = SET_FLYER;
  constructor(public flyer: Flyer) {}
}
export class CreateFlyer implements Action {
  readonly type = CREATE_FLYER_ON_MODAL;
  constructor(public flyer: Flyer) {}
}
export class DeleteFlyer implements Action {
  readonly type = DELETE_FLYER;
  constructor(public flyerId: string) {}
}

export type All = SetFlyers | ConcatFlyers | SetAlbumFlyers | ConcatAlbumFlyers | SetFlyer | CreateFlyer | DeleteFlyer;

export interface State {
  flyers: { [id: string]: Flyer };
  flyerIds: string[];
  albumFlyerIds: { [albumId: string]: string[] };
}
export const initialState: State = {
  flyers: {},
  flyerIds: [],
  albumFlyerIds: {},
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case SET_FLYERS: {
      const ids = action.flyers.map((flyer) => flyer.id);
      const flyers = { ...state.flyers };
      action.flyers.forEach((flyer) => {
        flyers[flyer.id] = flyer;
      });
      return { ...state, flyerIds: ids, flyers };
    }
    case CONCAT_FLYERS: {
      const ids = action.flyers.map((flyer) => flyer.id);
      const flyers = { ...state.flyers };
      action.flyers.forEach((flyer) => {
        flyers[flyer.id] = flyer;
      });
      return { ...state, flyerIds: state.flyerIds.concat(ids), flyers };
    }
    case SET_ALBUM_FLYERS: {
      const ids = action.flyers.map((flyer) => flyer.id);
      const flyers = { ...state.flyers };
      const albumFlyerIds = { ...state.albumFlyerIds, [action.albumId]: ids };
      action.flyers.forEach((flyer) => {
        flyers[flyer.id] = flyer;
      });
      return { ...state, albumFlyerIds, flyers };
    }
    case CONCAT_ALBUM_FLYERS: {
      const ids = action.flyers.map((flyer) => flyer.id);
      const flyers = { ...state.flyers };
      action.flyers.forEach((flyer) => {
        flyers[flyer.id] = flyer;
      });
      state.albumFlyerIds[action.albumId].concat(ids);
      return { ...state, flyers };
    }
    case SET_FLYER: {
      const flyer = action.flyer;
      const flyers = state.flyers;
      flyers[flyer.id] = flyer;
      const ids = flyers[flyer.id] ? state.flyerIds : [].concat(state.flyerIds, flyer.id);
      return { ...state, flyers, flyerIds: ids };
    }
    case CREATE_FLYER_ON_MODAL: {
      const flyer = action.flyer;
      const flyers = state.flyers;
      flyers[flyer.id] = flyer;
      const ids = [].concat(state.flyerIds, flyer.id);
      return { ...state, flyers, flyerIds: ids };
    }
    case DELETE_FLYER: {
      const ids = state.flyerIds.filter((id) => id !== action.flyerId);
      const flyers = state.flyers;
      delete flyers[action.flyerId];
      return { ...state, flyers, flyerIds: ids };
    }
    default: {
      return state;
    }
  }
}

export const selectFeature = createFeatureSelector<State>('flyer');

export const getFlyer = (id: string) => {
  return createSelector(selectFeature, (state: State) => state.flyers[id]);
};

export const getFlyers = createSelector(selectFeature, (state: State) => {
  return state.flyerIds.map((id) => state.flyers[id]);
});

export const getAlbumFlyers = (albumId: string) => {
  return createSelector(selectFeature, (state: State) => {
    return state.albumFlyerIds[albumId] ? state.albumFlyerIds[albumId].map((id) => state.flyers[id]) : [];
  });
};
