import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Popup } from '@/models';

@Injectable()
export class PopupApi {
  constructor(private http: HttpClient) {}

  fetchTrackPopups(trackId: string): Observable<Popup[]> {
    return this.http.get<Popup[]>(`tracks/${trackId}/popups`);
  }

  fetchAllPopups(albumId: string): Observable<Popup[]> {
    return this.http.get<Popup[]>(`popups?album_id=${albumId}`);
  }

  createPopup(trackId: string, params: any): Observable<Popup> {
    return this.http.post<Popup>(`tracks/${trackId}/popups`, params);
  }

  deletePopup(id: string): Observable<any> {
    return this.http.delete<any>(`popups/${id}`);
  }

  issuePolicy(originalName: string, size: number, contentType: string): Observable<any> {
    const url = 'popups/publish_policy';
    return this.http.post(
      url,
      { medium: { original_name: originalName, size, content_type: contentType } },
      { headers: new HttpHeaders().set('x-meta-respect-original-key-name', '') }
    );
  }
}
