import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DiscountTicket } from '@/models';

@Injectable()
export class DiscountTicketApi {
  constructor(private http: HttpClient) {}

  fetchDiscountTicket(albumId: string): Observable<DiscountTicket> {
    return this.http.get<DiscountTicket>(`albums/${albumId}/discount_ticket`);
  }

  createDiscountTicket(albumId: string, params: any): Observable<DiscountTicket> {
    return this.http.post<DiscountTicket>(`albums/${albumId}/discount_ticket`, params);
  }

  updateDiscountTicket(albumId: string, params: any): Observable<DiscountTicket> {
    return this.http.put<DiscountTicket>(`albums/${albumId}/discount_ticket`, params);
  }

  deleteDiscountTicket(albumId: string): Observable<any> {
    return this.http.delete<any>(`albums/${albumId}/discount_ticket`);
  }
}
