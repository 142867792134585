import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Comment } from '@/models';

export const SET_COMMENTS = '[Comment] SetComments';
export const SET_COMMENT = '[Comment] SetComment';
export const SET_ALBUM_COMMENTS = '[Comment] SetAlbumComments';
export const DELETE_COMMENT = '[Comment] DeleteComment';

export class SetComments implements Action {
  readonly type = SET_COMMENTS;
  constructor(public comments: Comment[], public init: boolean = false) {}
}
export class SetComment implements Action {
  readonly type = SET_COMMENT;
  constructor(public comment: Comment) {}
}
export class SetAlbumComments implements Action {
  readonly type = SET_ALBUM_COMMENTS;
  constructor(public albumId: string, public comments: Comment[], public init: boolean = false) {}
}
export class DeleteComment implements Action {
  readonly type = DELETE_COMMENT;
  constructor(public commentId: string) {}
}

export type All = SetComments | SetComment | SetAlbumComments | DeleteComment;

export interface State {
  comments: { [id: string]: Comment };
  commentIds: string[];
  albumCommentIds: { [albumId: string]: string[] };
}
export const initialState: State = {
  comments: {},
  commentIds: [],
  albumCommentIds: {},
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case SET_COMMENTS: {
      const ids = action.comments.map((comment) => comment.id);
      const comments = { ...state.comments };
      action.comments.forEach((comment) => {
        comments[comment.id] = comment;
      });
      return {
        ...state,
        commentIds: action.init ? ids : Array.from(new Set(state.commentIds.concat(ids))),
        comments,
      };
    }
    case SET_COMMENT: {
      const comment = action.comment;
      const comments = state.comments;
      comments[comment.id] = comment;
      const ids = comments[comment.id] ? state.commentIds : [].concat(state.commentIds, comment.id);
      return { ...state, comments, commentIds: ids };
    }
    case SET_ALBUM_COMMENTS: {
      const ids = action.comments.map((comment) => comment.id);
      const comments = { ...state.comments };
      const albumCommentIds = { ...state.albumCommentIds, [action.albumId]: ids };
      action.comments.forEach((comment) => {
        comments[comment.id] = comment;
      });
      return { ...state, albumCommentIds, comments };
    }
    case DELETE_COMMENT: {
      const ids = state.commentIds.filter((id) => id !== action.commentId);
      const comments = state.comments;
      delete comments[action.commentId];
      return { ...state, comments, commentIds: ids };
    }
    default: {
      return state;
    }
  }
}

export const selectFeature = createFeatureSelector<State>('comment');

export const getComment = (id: string) => {
  return createSelector(selectFeature, (state: State) => state.comments[id]);
};

export const getComments = createSelector(selectFeature, (state: State) => {
  return state.commentIds.map((id) => state.comments[id]);
});

export const getAlbumComments = (albumId: string) => {
  return createSelector(selectFeature, (state: State) => {
    return state.albumCommentIds[albumId] ? state.albumCommentIds[albumId].map((id) => state.comments[id]) : [];
  });
};
