import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Spot } from '@/models';

export const SET_SPOTS = '[Spot] SetSpots';
export const CONCAT_SPOTS = '[Spot] ConcatSpots';
export const SET_MAP_SPOTS = '[Spot] SetMapSpots';
export const SET_ALBUM_SPOTS = '[Spot] SetAlbumSpots';
export const CONCAT_ALBUM_SPOTS = '[Spot] ConcatAlbumSpots';
export const SET_SPOT = '[Spot] SetSpot';
export const DELETE_SPOT = '[Spot] DeleteSpot';

export class SetSpots implements Action {
  readonly type = SET_SPOTS;
  constructor(public spots: Spot[]) {}
}
export class ConcatSpots implements Action {
  readonly type = CONCAT_SPOTS;
  constructor(public spots: Spot[]) {}
}
export class SetMapSpots implements Action {
  readonly type = SET_MAP_SPOTS;
  constructor(public spots: Spot[]) {}
}
export class SetAlbumSpots implements Action {
  readonly type = SET_ALBUM_SPOTS;
  constructor(public albumId: string, public spots: Spot[]) {}
}
export class ConcatAlbumSpots implements Action {
  readonly type = CONCAT_ALBUM_SPOTS;
  constructor(public albumId: string, public spots: Spot[]) {}
}
export class SetSpot implements Action {
  readonly type = SET_SPOT;
  constructor(public spot: Spot) {}
}
export class DeleteSpot implements Action {
  readonly type = DELETE_SPOT;
  constructor(public spotId: string) {}
}

export type All = SetSpots | ConcatSpots | SetMapSpots | SetAlbumSpots | ConcatAlbumSpots | SetSpot | DeleteSpot;

export interface State {
  spots: { [id: string]: Spot };
  spotIds: string[];
  mapSpotIds: string[];
  albumSpotIds: { [albumId: string]: string[] };
}
export const initialState: State = {
  spots: {},
  spotIds: [],
  mapSpotIds: [],
  albumSpotIds: {},
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case SET_SPOTS: {
      const ids = action.spots.map((spot) => spot.id);
      const spots = { ...state.spots };
      action.spots.forEach((spot) => {
        spots[spot.id] = spot;
      });
      return { ...state, spotIds: ids, spots };
    }
    case CONCAT_SPOTS: {
      const ids = action.spots.map((spot) => spot.id);
      const spots = { ...state.spots };
      action.spots.forEach((spot) => {
        spots[spot.id] = spot;
      });
      return { ...state, spotIds: state.spotIds.concat(ids), spots };
    }
    case SET_MAP_SPOTS: {
      const mapSpotIds = action.spots.map((spot) => spot.id);
      const spots = { ...state.spots };
      action.spots.forEach((spot) => {
        spots[spot.id] = spot;
      });
      return { ...state, mapSpotIds, spots };
    }
    case SET_ALBUM_SPOTS: {
      const ids = action.spots.map((spot) => spot.id);
      const spots = { ...state.spots };
      const albumSpotIds = { ...state.albumSpotIds, [action.albumId]: ids };
      action.spots.forEach((spot) => {
        spots[spot.id] = spot;
      });
      return { ...state, albumSpotIds, spots };
    }
    case CONCAT_ALBUM_SPOTS: {
      const ids = action.spots.map((spot) => spot.id);
      const spots = { ...state.spots };
      action.spots.forEach((spot) => {
        spots[spot.id] = spot;
      });
      const albumSpotIds = state.albumSpotIds;
      albumSpotIds[action.albumId] = albumSpotIds[action.albumId].concat(ids);
      return { ...state, albumSpotIds, spots };
    }
    case SET_SPOT: {
      const spot = action.spot;
      const spots = state.spots;
      spots[spot.id] = spot;
      const ids = spots[spot.id] ? state.spotIds : [].concat(state.spotIds, spot.id);
      return { ...state, spots, spotIds: ids };
    }
    case DELETE_SPOT: {
      const ids = state.spotIds.filter((id) => id !== action.spotId);
      const spots = state.spots;
      delete spots[action.spotId];
      return { ...state, spots, spotIds: ids };
    }
    default: {
      return state;
    }
  }
}

export const selectFeature = createFeatureSelector<State>('spot');

export const getSpot = (id: string) => {
  return createSelector(selectFeature, (state: State) => state.spots[id]);
};

export const getSpots = createSelector(selectFeature, (state: State) => {
  return state.spotIds.map((id) => state.spots[id]);
});

export const getMapSpots = createSelector(selectFeature, (state: State) => {
  return state.mapSpotIds.map((id) => state.spots[id]);
});

export const getAlbumSpots = (albumId: string) => {
  return createSelector(selectFeature, (state: State) => {
    return state.albumSpotIds[albumId] ? state.albumSpotIds[albumId].map((id) => state.spots[id]) : [];
  });
};
