import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TicketInformationApi } from '@/apis';
import { TicketInformation } from '@/models';
import { AppState } from '@/store';
import { SetTicketInformation, DeleteTicketInformation } from '@/store/ticket-information.store';

@Injectable()
export class TicketInformationService {
  constructor(private store: Store<AppState>, private api: TicketInformationApi) {}

  fetchTicketInformation(albumId: string): Observable<TicketInformation> {
    return this.api
      .fetchTicketInformation(albumId)
      .pipe(tap((ticketInformation) => this.store.dispatch(new SetTicketInformation(albumId, ticketInformation))));
  }

  createTicketInformation(albumId: string, body: any): Observable<TicketInformation> {
    return this.api
      .createTicketInformation(albumId, body)
      .pipe(tap((ticketInformation) => this.store.dispatch(new SetTicketInformation(albumId, ticketInformation))));
  }

  updateTicketInformation(albumId: string, params: any): Observable<TicketInformation> {
    return this.api
      .updateTicketInformation(albumId, params)
      .pipe(tap((ticketInformation) => this.store.dispatch(new SetTicketInformation(albumId, ticketInformation))));
  }

  deleteTicketInformation(albumId: string): Observable<any> {
    return this.api
      .deleteTicketInformation(albumId)
      .pipe(tap(() => this.store.dispatch(new DeleteTicketInformation(albumId))));
  }
}
