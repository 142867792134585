import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Flyer } from '@/models';

@Injectable()
export class FlyerApi {
  constructor(private http: HttpClient) {}

  fetchFlyers(page: number = 1, perPage: number = 20): Observable<Flyer[]> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http.get<Flyer[]>('flyers', { params });
  }

  fetchAlbumFlyers(albumId: string, page: number = 1, perPage: number = 20): Observable<Flyer[]> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http.get<Flyer[]>(`albums/${albumId}/flyers`, { params });
  }

  fetchFlyer(id: string): Observable<Flyer> {
    return this.http.get<Flyer>(`flyers/${id}`);
  }

  createFlyer(params: any): Observable<Flyer> {
    return this.http.post<Flyer>('flyers', params);
  }

  updateFlyer(id: string, params: any): Observable<Flyer> {
    return this.http.put<Flyer>(`flyers/${id}`, params);
  }

  deleteFlyer(id: string): Observable<any> {
    return this.http.delete<any>(`flyers/${id}`);
  }

  searchFlyers(params: any): Observable<Flyer[]> {
    return this.http.get<Flyer[]>('flyers/search', { params });
  }

  issuePolicy(originalName: string, size: number, contentType: string): Observable<any> {
    const url = 'flyers/publish_policy';
    return this.http.post(
      url,
      { medium: { original_name: originalName, size, content_type: contentType } },
      { headers: new HttpHeaders().set('x-meta-respect-original-key-name', '') }
    );
  }

  issuePdfPolicy(originalName: string, size: number, contentType: string): Observable<any> {
    const url = 'flyers/pdf_policy';
    return this.http.post(
      url,
      { pdf: { original_name: originalName, size, content_type: contentType } },
      { headers: new HttpHeaders().set('x-meta-respect-original-key-name', '') }
    );
  }
}
