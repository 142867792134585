import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TicketInformation } from '@/models';

export const SET_TICKET_INFORMATION = '[TicketInformation] SetTicketInformation';
export const DELETE_TICKET_INFORMATION = '[TicketInformation] DeleteTicketInformation';

export class SetTicketInformation implements Action {
  readonly type = SET_TICKET_INFORMATION;
  constructor(public albumId: string, public ticketInformation: TicketInformation) {}
}
export class DeleteTicketInformation implements Action {
  readonly type = DELETE_TICKET_INFORMATION;
  constructor(public albumId: string) {}
}

export type All = SetTicketInformation | DeleteTicketInformation;

export interface State {
  ticketInformations: { [id: string]: TicketInformation };
  albumIds: string[];
}
export const initialState: State = {
  ticketInformations: {},
  albumIds: [],
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case SET_TICKET_INFORMATION: {
      const ticketInformation = action.ticketInformation;
      const ticketInformations = state.ticketInformations;
      ticketInformations[action.albumId] = ticketInformation;
      const ids = ticketInformations[action.albumId] ? state.albumIds : [].concat(state.albumIds, action.albumId);
      return { ...state, ticketInformations, albumIds: ids };
    }
    case DELETE_TICKET_INFORMATION: {
      const ids = state.albumIds.filter((id) => id !== action.albumId);
      const ticketInformations = state.ticketInformations;
      delete ticketInformations[action.albumId];
      return { ...state, ticketInformations, albumIds: ids };
    }
    default: {
      return state;
    }
  }
}

export const selectFeature = createFeatureSelector<State>('ticketInformation');

export const getTicketInformation = (albumId: string) => {
  return createSelector(selectFeature, (state: State) => state.ticketInformations[albumId]);
};
