import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import { LogLevel } from '@sentry/types';
import { filter } from 'rxjs/operators';

import { environment } from '@environment';

import { AppState } from '@/store';
import { getFirebaseUser } from '@/store/session.store';

declare let process;

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
    Sentry.init({
      enabled: environment.production && location.host !== 'localhost:4200',
      environment: environment.name,
      debug: environment.name !== 'production',
      dsn: environment.sentryDsn,
      transport: Sentry.Transports.FetchTransport,
      release: process.env.CI_COMMIT_SHA || process.env.REVISION,
      logLevel: LogLevel.Error,
      ignoreErrors: [
        /network error/i,
        /invalid action/i,
        /loading chunk/i,
        /No Firebase App/i,
        /Non-Error exception captured/i,
      ],
      integrations: [new Sentry.Integrations.GlobalHandlers({ onerror: false, onunhandledrejection: false })],
    });
    Sentry.configureScope((scope) => {
      scope.setTag('env', environment.name);
    });
  }

  extractError(error) {
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    if (error instanceof HttpErrorResponse) {
      // ネットワーク不通と400番以上のエラーではSentryにあげない
      if (error.status === 0 || error.status >= 400) {
        return null;
      }

      if (error.error instanceof Error) {
        return error.error;
      }

      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      return error.message;
    }

    return 'Handled unknown error';
  }

  handleError(error: any): void {
    const extractedError = this.extractError(error);

    if (extractedError !== null) {
      if (!environment.production) {
        console.error(extractedError);
      }
      Sentry.captureException(extractedError);
    }
  }
}

export const errorHandlerProvider = {
  provide: ErrorHandler,
  useClass: SentryErrorHandler,
};

export function setUserScope(store: Store<AppState>) {
  store
    .pipe(
      select(getFirebaseUser),
      filter((u) => !!u)
    )
    .subscribe((user) => {
      Sentry.configureScope((scope) => {
        scope.setUser({ id: user ? user.uid : null });
      });
    });
}
