import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SpotAlbum } from '@/models';

export const SET_SPOT_ALBUMS = '[SpotAlbum] SetSpotAlbums';
export const SET_SPOT_ALBUM = '[SpotAlbum] SetSpotAlbum';
export const CREATE_SPOT_ALBUM_ON_MODAL = '[SpotAlbum] CreateSpotAlbum';
export const DELETE_SPOT_ALBUM = '[SpotAlbum] DeleteSpotAlbum';

export class SetSpotAlbums implements Action {
  readonly type = SET_SPOT_ALBUMS;
  constructor(public albumId: string, public spotAlbums: SpotAlbum[]) {}
}
export class SetSpotAlbum implements Action {
  readonly type = SET_SPOT_ALBUM;
  constructor(public spotAlbum: SpotAlbum) {}
}
export class CreateSpotAlbum implements Action {
  readonly type = CREATE_SPOT_ALBUM_ON_MODAL;
  constructor(public spotAlbum: SpotAlbum) {}
}
export class DeleteSpotAlbum implements Action {
  readonly type = DELETE_SPOT_ALBUM;
  constructor(public spotAlbumId: string) {}
}

export type All = SetSpotAlbums | SetSpotAlbum | CreateSpotAlbum | DeleteSpotAlbum;

export interface State {
  spotAlbums: { [id: string]: SpotAlbum };
  spotAlbumIds: { [albumId: string]: string[] };
}
export const initialState: State = {
  spotAlbums: {},
  spotAlbumIds: {},
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case SET_SPOT_ALBUMS: {
      const ids = action.spotAlbums.map((spotAlbum) => spotAlbum.id);
      const spotAlbumIds = { ...state.spotAlbumIds, [action.albumId]: ids };
      const spotAlbums = { ...state.spotAlbums };
      action.spotAlbums.forEach((spotAlbum) => {
        spotAlbums[spotAlbum.id] = spotAlbum;
      });
      return { ...state, spotAlbums, spotAlbumIds };
    }
    case SET_SPOT_ALBUM: {
      const spotAlbum = action.spotAlbum;
      const spotAlbums = state.spotAlbums;
      spotAlbums[spotAlbum.id] = spotAlbum;
      const spotAlbumIds = state.spotAlbumIds;
      if (spotAlbums[spotAlbum.id]) {
        spotAlbumIds[spotAlbum.album.id].push(spotAlbum.id);
      }
      return { ...state, spotAlbums, spotAlbumIds };
    }
    case CREATE_SPOT_ALBUM_ON_MODAL: {
      const spotAlbum = action.spotAlbum;
      const spotAlbums = state.spotAlbums;
      spotAlbums[spotAlbum.id] = spotAlbum;
      state.spotAlbumIds[spotAlbum.album.id].push(spotAlbum.id);
      return { ...state, spotAlbums };
    }
    case DELETE_SPOT_ALBUM: {
      const spotAlbumIds = state.spotAlbumIds;
      for (const [albumId, ids] of Object.entries(spotAlbumIds)) {
        spotAlbumIds[albumId] = ids.filter((id) => id !== action.spotAlbumId);
      }
      const spotAlbums = state.spotAlbums;
      delete spotAlbums[action.spotAlbumId];
      return { ...state, spotAlbums, spotAlbumIds };
    }
    default: {
      return state;
    }
  }
}

export const selectFeature = createFeatureSelector<State>('spotAlbum');

export const getSpotAlbums = (albumId: string) => {
  return createSelector(selectFeature, (state: State) => {
    return state.spotAlbumIds[albumId] ? state.spotAlbumIds[albumId].map((id) => state.spotAlbums[id]) : [];
  });
};
