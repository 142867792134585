import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TicketInformation } from '@/models';

@Injectable()
export class TicketInformationApi {
  constructor(private http: HttpClient) {}

  fetchTicketInformation(albumId: string): Observable<TicketInformation> {
    return this.http.get<TicketInformation>(`albums/${albumId}/ticket_information`);
  }

  createTicketInformation(albumId: string, params: any): Observable<TicketInformation> {
    return this.http.post<TicketInformation>(`albums/${albumId}/ticket_information`, params);
  }

  updateTicketInformation(albumId: string, params: any): Observable<TicketInformation> {
    return this.http.put<TicketInformation>(`albums/${albumId}/ticket_information`, params);
  }

  deleteTicketInformation(albumId: string): Observable<any> {
    return this.http.delete<any>(`albums/${albumId}/ticket_information`);
  }
}
