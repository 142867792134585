import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { setUserScope } from './libs/error-handler';
import { AppState } from './store';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  ready$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private store: Store<AppState>
  ) {
    this.initializeApp();

    setUserScope(this.store);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.ready$.next(true);
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
