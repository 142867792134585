import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DeleteMetadataInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let headers = req.headers;

    // NOTE: 現状、Interceptor は Global で指定されてしまうため、無視する手段がない。
    // そこで、Interceptor に伝えるために header メタデータを追加する方法(回避策)がある。
    // この方法を使った場合、headerの消し忘れ等が発生しうるため、ここで定義したものがすべて削除されているかを検証する。
    // if (headers.has('x-meta-hogehoge')) {
    //   headers = headers.delete('x-meta-hogehoge')
    // }

    if (headers.has('x-meta-no-cache')) {
      headers = headers.delete('x-meta-no-cache');
    }
    if (headers.has('x-meta-refresh-cache')) {
      headers = headers.delete('x-meta-refresh-cache');
    }
    if (headers.has('x-meta-no-change-resource')) {
      headers = headers.delete('x-meta-no-change-resource');
    }
    if (headers.has('x-meta-respect-original-key-name')) {
      headers = headers.delete('x-meta-respect-original-key-name');
    }
    if (headers.has('x-meta-use-json-api')) {
      headers = headers.delete('x-meta-use-json-api');
    }
    return next.handle(req.clone({ headers }));
  }
}
