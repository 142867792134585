import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { MapMarkerApi } from '@/apis';
import { AppState } from '@/store';

@Injectable()
export class MapMarkerService {
  constructor(private store: Store<AppState>, private api: MapMarkerApi) {}

  deleteMapMarker(id: string): Observable<any> {
    return this.api.deleteMapMarker(id);
  }
}
