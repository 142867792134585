import { HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Deserializer, IDeserializerOptions } from 'jsonapi-serializer';
import { Observable, of, from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { environment } from '@environment';

@Injectable()
export class ApiResponseInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      mergeMap((event) => {
        if (
          event instanceof HttpResponse &&
          this.isInternalApi(req.url) &&
          !req.headers.has('x-meta-no-change-resource')
        ) {
          const keepOriginalKeyName = req.headers.has('x-meta-respect-original-key-name');
          return this.deserialize(event.body, keepOriginalKeyName).pipe(map((body) => event.clone({ body })));
        } else {
          return of(event);
        }
      })
    );
  }

  private deserialize(obj: any, keepOriginalKeyName: boolean): Observable<any> {
    if (!obj || !obj.data) {
      return from(new Promise((resolve, reject) => resolve(null)));
    }
    const option: IDeserializerOptions = {};
    if (keepOriginalKeyName) {
      option.keyForAttribute = (name) => name;
    } else {
      option.keyForAttribute = 'camelCase';
    }
    option.typeAsAttribute = true;
    return from(new Deserializer(option).deserialize(obj));
  }
  private isInternalApi(url: string): boolean {
    // NOTE: cannot contain hostname in url excluding hostname
    return url.includes(environment.api.host);
  }
}
