import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { ComponentsModule } from '@/components/components.module';
import { DirectivesModule } from '@/directives/directives.module';
import { TutorialPage } from '@/pages/tutorial/tutorial.page';
import { PipesModule } from '@/pipes/pipes.module';

import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';

@NgModule({
  declarations: [MainLayoutComponent, AuthLayoutComponent, TutorialPage],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ComponentsModule,
    DirectivesModule,
    PipesModule,
  ],
  exports: [MainLayoutComponent, AuthLayoutComponent],
  entryComponents: [TutorialPage],
})
export class LayoutsModule {}
