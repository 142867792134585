import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { normalizeKeys } from 'object-keys-normalizer';

import { environment, Environment } from '@environment';

@Injectable({ providedIn: 'root' })
export class ApiRequestInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let requestUrl;
    let headers: HttpHeaders = req.headers;
    let body = req.body;
    let params = req.params;

    if (this.isInternalApiReq(req.url)) {
      requestUrl = `${this.buildHttpHost(environment)}/collaborator/api/${req.url}`;
      params = this.toSnakeCaseParams(params);
      if (['POST', 'PUT', 'PATCH'].includes(req.method)) {
        if (!headers.has('x-meta-no-change-content-type')) {
          headers = headers.set('Content-Type', 'application/json');
        }
        if (!headers.has('x-meta-no-change-request-body')) {
          body = normalizeKeys(_.cloneDeep(req.body), (k) => {
            if (k.charAt() === '_') {
              return k;
            }
            return this.toSnakeCaseString(k);
          });
        }
      }
    } else {
      requestUrl = req.url;
    }

    const newReq = req.clone({ headers, body, url: requestUrl, params });
    return next.handle(newReq);
  }

  private buildHttpHost(env: Environment): string {
    const scheme = env.api.ssl ? 'https' : 'http';
    const port = env.api.port !== 80 && env.api.port !== 443 ? `:${env.api.port}` : '';
    return `${scheme}://${env.api.host}${port}`;
  }
  private isInternalApiReq(url: string): boolean {
    // NOTE: scheme が指定されている場合は外部リクエストとして処理する
    return !/(http(s)?:\/\/+)/.test(url) && !url.includes(environment.api.host);
  }
  private toSnakeCaseParams(httpParams: HttpParams) {
    let newParams = new HttpParams();
    httpParams.keys().forEach((key) => {
      const values = httpParams.getAll(key);
      const snakedKey = this.toSnakeCaseString(key);
      if (values.length > 1) {
        values.forEach((value) => (newParams = newParams.append(snakedKey, value)));
      } else {
        newParams = newParams.set(snakedKey, values[0]);
      }
    });
    return newParams;
  }
  private toSnakeCaseString(str: string) {
    return str
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
  }
}
