import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Cast } from '@/models';

@Injectable()
export class CastApi {
  constructor(private http: HttpClient) {}

  fetchCasts(page: number = 1, perPage: number = 20): Observable<Cast[]> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http.get<Cast[]>('casts', { params });
  }

  fetchCast(id: string): Observable<Cast> {
    return this.http.get<Cast>(`casts/${id}`);
  }

  createCast(params: any): Observable<Cast> {
    return this.http.post<Cast>('casts', params);
  }

  updateCast(id: string, params: any): Observable<Cast> {
    return this.http.put<Cast>(`casts/${id}`, params);
  }

  deleteCast(id: string): Observable<any> {
    return this.http.delete<any>(`casts/${id}`);
  }

  searchCasts(params: any): Observable<Cast[]> {
    return this.http.get<Cast[]>('casts/search', { params });
  }

  issuePolicy(originalName: string, size: number, contentType: string): Observable<any> {
    const url = 'casts/publish_policy';
    return this.http.post(
      url,
      { medium: { original_name: originalName, size, content_type: contentType } },
      { headers: new HttpHeaders().set('x-meta-respect-original-key-name', '') }
    );
  }
}
