import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SpotApi } from '@/apis';
import { PAGE_SIZE } from '@/config';
import { PaginatedList } from '@/libs/paginated-list';
import { Spot } from '@/models';
import { AppState } from '@/store';
import {
  SetSpots,
  SetSpot,
  ConcatSpots,
  SetMapSpots,
  SetAlbumSpots,
  ConcatAlbumSpots,
  DeleteSpot,
} from '@/store/spot.store';

import { UploadService } from './upload.service';

@Injectable()
export class SpotService {
  constructor(private store: Store<AppState>, private api: SpotApi, private uploadService: UploadService) {}
  spotPage: PaginatedList<Spot> = new PaginatedList<Spot>(PAGE_SIZE);

  fetchSpots(isRefresh: boolean = true): Observable<Spot[]> {
    const page = isRefresh ? 1 : this.spotPage.page + 1;
    return this.api.fetchSpots(page, PAGE_SIZE).pipe(
      tap((spots) => {
        if (isRefresh) {
          this.store.dispatch(new SetSpots(spots));
        } else {
          this.store.dispatch(new ConcatSpots(spots));
        }
        this.spotPage.page = page;
        this.spotPage.setPage(spots);
      })
    );
  }

  fetchSpotsWithCoords(latitude: number, longitude: number, scale: number): Observable<Spot[]> {
    return this.api
      .fetchSpotsWithCoords(latitude, longitude, scale)
      .pipe(tap((spots) => this.store.dispatch(new SetMapSpots(spots))));
  }

  fetchSpot(id: string): Observable<Spot> {
    return this.api.fetchSpot(id).pipe(tap((spot) => this.store.dispatch(new SetSpot(spot))));
  }

  createSpot(body: any): Observable<Spot> {
    return this.api.createSpot(body).pipe(tap((spot) => this.store.dispatch(new SetSpot(spot))));
  }

  updateSpot(id: string, params: any): Observable<Spot> {
    return this.api.updateSpot(id, params).pipe(tap((spot) => this.store.dispatch(new SetSpot(spot))));
  }

  deleteSpot(id: string): Observable<any> {
    return this.api.deleteSpot(id).pipe(tap(() => this.store.dispatch(new DeleteSpot(id))));
  }

  searchSpots(params: any, isRefresh: boolean = true): Observable<Spot[]> {
    const page = isRefresh ? 1 : this.spotPage.page + 1;
    return this.api.searchSpots({ ...params, page, per_page: PAGE_SIZE }).pipe(
      tap((spots) => {
        if (isRefresh) {
          this.store.dispatch(new SetSpots(spots));
        } else {
          this.store.dispatch(new ConcatSpots(spots));
        }
        this.spotPage.page = page;
        this.spotPage.setPage(spots);
      })
    );
  }

  searchAlbumSpots(albumId: string, params: any, isRefresh: boolean = true): Observable<Spot[]> {
    const page = isRefresh ? 1 : this.spotPage.page + 1;
    return this.api.searchSpots({ ...params, page, per_page: PAGE_SIZE }).pipe(
      tap((spots) => {
        if (isRefresh) {
          this.store.dispatch(new SetAlbumSpots(albumId, spots));
        } else {
          this.store.dispatch(new ConcatAlbumSpots(albumId, spots));
        }
        this.spotPage.page = page;
        this.spotPage.setPage(spots);
      })
    );
  }

  uploadPhotoLibrary(fileList: FileList): Observable<any[]> {
    if (!fileList) {
      return;
    }

    const files = Array.from(fileList);
    return forkJoin(
      files.map((file) => {
        const policy$ = this.api.publishPolicyForPhotos(file.name, file.size, file.type);
        return this.uploadService.uploadImageUsingPolicy(file, policy$);
      })
    );
  }

  registerPhotoWithURL(): Observable<any[]> {
    return this.api.publishPolicyForPhotos('spotImage', 1024, 'image/jpeg');
  }
}
