import { AgmCoreModule } from '@agm/core';
import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as firebase from 'firebase/app';

import { Firebase } from '@ionic-native/firebase/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { environment } from '@environment';

import { ApisModule } from '@/apis/apis.module';
import { ComponentsModule } from '@/components/components.module';
import { DirectivesModule } from '@/directives/directives.module';
import { AuthGuard } from '@/guards/auth/auth.guard';
import { httpInterceptorProviders } from '@/http-interceptors';
import { LayoutsModule } from '@/layouts/layouts.module';
import { errorHandlerProvider } from '@/libs/error-handler';
import { PipesModule } from '@/pipes/pipes.module';
import { SessionService } from '@/services';
import { ServicesModule } from '@/services/services.module';
import { metaReducers, reducers } from '@/store';

import 'firebase/auth';
import 'firebase/firestore';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

firebase.initializeApp(environment.firebaseConfig);

export function jwtOptionsFactory(injector) {
  return {
    tokenGetter: () => injector.get(SessionService).getIdToken(),
    whitelistedDomains: [`${environment.api.host}:${environment.api.port}`],
    blacklistedRoutes: [`${environment.api.host}:${environment.api.port}/api/public`],
  };
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    DirectivesModule,
    PipesModule,
    ApisModule,
    ServicesModule,
    LayoutsModule,
    HttpClientModule,
    IonicStorageModule.forRoot({ name: 'MimitabContentManagerLocalStorageDb' }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    environment.production === undefined || environment.production
      ? []
      : StoreDevtoolsModule.instrument({ maxAge: 50 }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Injector],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA3Z3n54Lk_wO-GcWGq_2265xtRfKNv6xM',
      libraries: ['places'],
    }),
    ReactiveFormsModule,
  ],
  providers: [
    httpInterceptorProviders,
    Firebase,
    StatusBar,
    SplashScreen,
    AuthGuard,
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    errorHandlerProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
