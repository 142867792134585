import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DiscountTicketApi } from '@/apis';
import { DiscountTicket } from '@/models';
import { AppState } from '@/store';
import { SetDiscountTicket, DeleteDiscountTicket } from '@/store/discount-ticket.store';

@Injectable()
export class DiscountTicketService {
  constructor(private store: Store<AppState>, private api: DiscountTicketApi) {}

  fetchDiscountTicket(albumId: string): Observable<DiscountTicket> {
    return this.api
      .fetchDiscountTicket(albumId)
      .pipe(tap((discountTicket) => this.store.dispatch(new SetDiscountTicket(albumId, discountTicket))));
  }

  createDiscountTicket(albumId: string, body: any): Observable<DiscountTicket> {
    return this.api
      .createDiscountTicket(albumId, body)
      .pipe(tap((discountTicket) => this.store.dispatch(new SetDiscountTicket(albumId, discountTicket))));
  }

  updateDiscountTicket(albumId: string, params: any): Observable<DiscountTicket> {
    return this.api
      .updateDiscountTicket(albumId, params)
      .pipe(tap((discountTicket) => this.store.dispatch(new SetDiscountTicket(albumId, discountTicket))));
  }

  deleteDiscountTicket(albumId: string): Observable<any> {
    return this.api
      .deleteDiscountTicket(albumId)
      .pipe(tap(() => this.store.dispatch(new DeleteDiscountTicket(albumId))));
  }
}
