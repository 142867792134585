import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '@/models';

@Injectable()
export class UserApi {
  websocketUrl = 'auth/websocket';

  constructor(private http: HttpClient) {}

  updateProfile(params: any): Observable<User> {
    return this.http.put<User>('user', params);
  }
  updateEmail(email: string): Observable<User> {
    return this.http.put<User>('user', { email });
  }
  get(): Observable<User> {
    return this.http.get<User>('me');
  }
  fetchCurrentUser(): Observable<User> {
    return this.http.get<User>('me');
  }
  initializeUser(body: any): Observable<User> {
    return this.http.post<User>('users', body);
  }
}
