import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Spot } from '@/models';

@Injectable()
export class SpotApi {
  constructor(private http: HttpClient) {}

  fetchSpots(page: number = 1, perPage: number = 20): Observable<Spot[]> {
    const params = new HttpParams().set('page', page.toString()).set('per_page', perPage.toString());
    return this.http.get<Spot[]>('spots', { params });
  }

  fetchSpotsWithCoords(latitude: number, longitude: number, scale: number): Observable<Spot[]> {
    return this.http.get<Spot[]>(`spots?latitude=${latitude}&longitude=${longitude}&radius=${scale}`);
  }

  fetchSpot(id: string): Observable<Spot> {
    return this.http.get<Spot>(`spots/${id}`);
  }

  createSpot(params: any): Observable<Spot> {
    return this.http.post<Spot>('spots', params);
  }

  updateSpot(id: string, params: any): Observable<Spot> {
    return this.http.put<Spot>(`spots/${id}`, params);
  }

  deleteSpot(id: string): Observable<any> {
    return this.http.delete<any>(`spots/${id}`);
  }

  searchSpots(params: any): Observable<Spot[]> {
    return this.http.get<Spot[]>('spots/search', { params });
  }

  publishPolicyForPhotos(originalName: string, size: number, contentType: string): Observable<any> {
    const url = 'spots/publish_policy';
    return this.http.post(
      url,
      { medium: { original_name: originalName, size, content_type: contentType } },
      { headers: new HttpHeaders().set('x-meta-respect-original-key-name', '') }
    );
  }
}
