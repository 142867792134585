import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { throwError, of, Observable } from 'rxjs';
import { filter, take, mergeMap, map, catchError } from 'rxjs/operators';

import { User } from '@/models/user';
import { SessionService, UserService } from '@/services';
import { AppState } from '@/store/index';
import { getFirebaseUser } from '@/store/session.store';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private sessionService: SessionService,
    private router: Router,
    private userService: UserService,
    private zone: NgZone
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(getFirebaseUser),
      filter((firebaseUser) => firebaseUser !== undefined),
      take(1),
      mergeMap((firebaseUser) => {
        if (firebaseUser === null) {
          // throw new Error('firebase_user is null');
          return throwError(firebaseUser);
        }
        // if (firebaseUser && firebaseUser.isAnonymous) {
        //   // セッションを維持するために再度サインインする
        //   this.sessionService.signInAnonymously();
        // }
        return this.userService.fetchCurrentUser().pipe(map((user) => [user, firebaseUser] as [User, firebase.User]));
        // return of(firebaseUser);
      }),
      map((res) => {
        if (res[0].status === 'approved') {
          return true;
        } else {
          this.zone.run(() => {
            this.router.navigateByUrl('/auth/applied');
          });
          return false;
        }
      }),
      catchError((_) => {
        this.zone.run(() => {
          this.router.navigateByUrl('/auth/login');
        });
        return of(false);
      })
    );
  }
}
