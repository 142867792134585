import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { DirectivesModule } from '@/directives/directives.module';
import { PipesModule } from '@/pipes/pipes.module';

import { AlbumItemComponent } from './album-item/album-item.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { ButtonComponent } from './button/button.component';
import { CastCommentComponent } from './cast-comment/cast-comment.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FlyerItemComponent } from './flyer-item/flyer-item.component';
import { OptionComponent } from './option/option.component';
import { SpotItemComponent } from './spot-item/spot-item.component';
import { WysiwygBodyComponent } from './wysiwyg-body/wysiwyg-body.component';
import { WysiwygEditorComponent } from './wysiwyg-editor/wysiwyg-editor.component';

@NgModule({
  declarations: [
    ButtonComponent,
    CastCommentComponent,
    SpotItemComponent,
    AlbumItemComponent,
    FlyerItemComponent,
    WysiwygBodyComponent,
    WysiwygEditorComponent,
    AutocompleteComponent,
    OptionComponent,
    DatePickerComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IonicModule, DirectivesModule, PipesModule],
  exports: [
    ButtonComponent,
    CastCommentComponent,
    SpotItemComponent,
    AlbumItemComponent,
    FlyerItemComponent,
    WysiwygBodyComponent,
    WysiwygEditorComponent,
    AutocompleteComponent,
    OptionComponent,
    DatePickerComponent,
  ],
})
export class ComponentsModule {}
