import { NgModule } from '@angular/core';

import { AlbumApi } from './album';
import { CastApi } from './cast';
import { CommentApi } from './comment';
import { DiscountTicketApi } from './discount-ticket';
import { EditorImageApi } from './editor-image';
import { FcmTopicApi } from './fcm-topic';
import { FloorMapApi } from './floor-map';
import { FlyerApi } from './flyer';
import { MapMarkerApi } from './map-marker';
import { NotificationDeliveryApi } from './notification-delivery';
import { PopupApi } from './popup';
import { SpotApi } from './spot';
import { SpotAlbumApi } from './spot-album';
import { TicketInformationApi } from './ticket-information';
import { TrackApi } from './track';
import { UploadApi } from './upload';
import { UserApi } from './user';

@NgModule({
  providers: [
    UserApi,
    AlbumApi,
    CastApi,
    CommentApi,
    FloorMapApi,
    PopupApi,
    MapMarkerApi,
    SpotApi,
    SpotAlbumApi,
    TrackApi,
    UploadApi,
    NotificationDeliveryApi,
    FcmTopicApi,
    FlyerApi,
    TicketInformationApi,
    DiscountTicketApi,
    EditorImageApi,
  ],
})
export class ApisModule {}
