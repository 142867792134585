import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class UploadApi {
  constructor(private http: HttpClient) {}

  uploadToS3(presignedUrl: string, form: FormData): Observable<any> {
    return this.http.post(presignedUrl, form, { reportProgress: true, observe: 'events' });
  }
}
