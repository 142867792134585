import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FcmTopicApi } from '@/apis';
import { FcmTopic } from '@/models';
import { UploadService } from '@/services/upload.service';
import { AppState } from '@/store';
import { SetFcmTopics } from '@/store/fcm-topic.store';

@Injectable()
export class FcmTopicService {
  constructor(private store: Store<AppState>, private api: FcmTopicApi, private uploadService: UploadService) {}

  fetchFcmTopics(): Observable<FcmTopic[]> {
    return this.api.fetchFcmTopics().pipe(tap((fcmTopics) => this.store.dispatch(new SetFcmTopics(fcmTopics))));
  }
}
