import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CommentApi } from '@/apis';
import { Comment } from '@/models';
import { AppState } from '@/store';
import { SetAlbumComments, SetComment, DeleteComment } from '@/store/comment.store';

@Injectable()
export class CommentService {
  constructor(private store: Store<AppState>, private api: CommentApi) {}

  fetchComments(albumId: string): Observable<Comment[]> {
    return this.api
      .fetchComments(albumId)
      .pipe(tap((comments) => this.store.dispatch(new SetAlbumComments(albumId, comments))));
  }

  fetchComment(id: string): Observable<Comment> {
    return this.api.fetchComment(id).pipe(tap((comment) => this.store.dispatch(new SetComment(comment))));
  }

  createComment(albumId: string, body: any): Observable<Comment> {
    return this.api.createComment(albumId, body).pipe(tap((comment) => this.store.dispatch(new SetComment(comment))));
  }

  updateComment(id: string, params: any): Observable<Comment> {
    return this.api.updateComment(id, params).pipe(tap((comment) => this.store.dispatch(new SetComment(comment))));
  }

  deleteComment(id: string): Observable<any> {
    return this.api.deleteComment(id).pipe(tap(() => this.store.dispatch(new DeleteComment(id))));
  }

  reorderComments(albumId: string, params: any): Observable<any> {
    return this.api.reorderComments(albumId, params);
  }
}
