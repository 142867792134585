import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { environment } from '@environment';

import { AuthGuard } from '@/guards/auth/auth.guard';
import { AuthLayoutComponent } from '@/layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from '@/layouts/main-layout/main-layout.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'content',
        pathMatch: 'full',
      },
      {
        path: 'spot',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/spot/spot-tabs/spot-tabs.module').then((m) => m.SpotTabsPageModule),
          },
          {
            path: 'detail/:id',
            loadChildren: () =>
              import('./pages/spot/spot-detail/spot-detail.module').then((m) => m.SpotDetailPageModule),
          },
          {
            path: 'form/:id',
            loadChildren: () => import('./pages/spot/spot-form/spot-form.module').then((m) => m.SpotFormPageModule),
          },
        ],
      },
      {
        path: 'content',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/content/album-list/album-list.module').then((m) => m.AlbumListPageModule),
          },
          {
            path: ':id',
            loadChildren: () =>
              import('./pages/content/album-tabs/album-tabs.module').then((m) => m.AlbumTabsPageModule),
          },
          {
            path: 'form/:id',
            loadChildren: () =>
              import('./pages/content/album-form/album-form.module').then((m) => m.AlbumFormPageModule),
          },
          {
            path: ':id/track/edit',
            loadChildren: () =>
              import('./pages/content/album-track-edit/album-track-edit.module').then(
                (m) => m.AlbumTrackEditPageModule
              ),
          },
          {
            path: ':id/track/edit-without-audio',
            loadChildren: () =>
              import('./pages/content/album-track-edit-without-audio/album-track-edit-without-audio.module').then(
                (m) => m.AlbumTrackEditWithoutAudioPageModule
              ),
          },
        ],
      },
      {
        path: 'cast',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/cast/cast-list/cast-list.module').then((m) => m.CastListPageModule),
          },
          // {
          //   path: 'detail/:id',
          //   loadChildren: () => import('./pages/cast/cast-detail/cast-detail.module').then(m => m.CastDetailPageModule),
          // }
          {
            path: 'form/:id',
            loadChildren: () => import('./pages/cast/cast-form/cast-form.module').then((m) => m.CastFormPageModule),
          },
        ],
      },
      {
        path: 'flyer',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/flyer/flyer-list/flyer-list.module').then((m) => m.FlyerListPageModule),
          },
          {
            path: 'form/:id',
            loadChildren: () => import('./pages/flyer/flyer-form/flyer-form.module').then((m) => m.FlyerFormPageModule),
          },
        ],
      },
      {
        path: 'notification-delivery',
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./pages/notification-delivery/notification-list/notification-list.module').then(
                (m) => m.NotificationListPageModule
              ),
          },
          {
            path: 'form/:id',
            loadChildren: () =>
              import('./pages/notification-delivery/notification-form/notification-form.module').then(
                (m) => m.NotificationFormPageModule
              ),
          },
        ],
      },
      {
        path: 'account',
        children: [
          {
            path: 'settings',
            loadChildren: () => import('./pages/account/settings/settings.module').then((m) => m.SettingsPageModule),
          },
        ],
      },
      {
        path: 'other',
        children: [
          {
            path: 'help',
            loadChildren: () => import('./pages/other/help/help.module').then((m) => m.HelpPageModule),
          },
        ],
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/auth/register/register.module').then((m) => m.RegisterPageModule),
      },
      {
        path: 'password-reset',
        loadChildren: () =>
          import('./pages/auth/password-reset/password-reset.module').then((m) => m.PasswordResetPageModule),
      },
      {
        path: 'applied',
        loadChildren: () => import('./pages/auth/applied/applied.module').then((m) => m.AppliedPageModule),
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

// dev.twogate.net向けにreload対応
const routerOption = Object.assign(
  { preloadingStrategy: PreloadAllModules, paramsInheritanceStrategy: 'always' },
  { useHash: environment.name === 'staging' }
);

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOption)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
