import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Popup } from '@/models';

export const SET_POPUPS = '[Popup] SetPopups';
export const SET_TRACK_POPUPS = '[Popup] SetTrackPopups';
export const CREATE_POPUP = '[Popup] CreatePopup';
export const DELETE_POPUP = '[Popup] DeletePopup';

export class SetPopups implements Action {
  readonly type = SET_POPUPS;
  constructor(public popups: Popup[]) {}
}
export class SetTrackPopups implements Action {
  readonly type = SET_TRACK_POPUPS;
  constructor(public trackId: string, public popups: Popup[]) {}
}
export class CreatePopup implements Action {
  readonly type = CREATE_POPUP;
  constructor(public trackId: string, public popup: Popup) {}
}
export class DeletePopup implements Action {
  readonly type = DELETE_POPUP;
  constructor(public trackId: string, public popupId: string) {}
}

export type All = SetPopups | SetTrackPopups | CreatePopup | DeletePopup;

export interface State {
  popups: { [id: string]: Popup };
  trackPopupIds: { [trackId: string]: string[] };
}
export const initialState: State = {
  popups: {},
  trackPopupIds: {},
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case SET_POPUPS: {
      const popups = state.popups;
      const trackPopupIds = state.trackPopupIds;

      action.popups.forEach((popup) => {
        if (!trackPopupIds[popup.trackId]) {
          trackPopupIds[popup.trackId] = [];
        }

        popups[popup.id] = popup;
        trackPopupIds[popup.trackId].push(popup.id);
      });

      return { ...state, trackPopupIds, popups };
    }
    case SET_TRACK_POPUPS: {
      const ids = action.popups.map((popup) => popup.id);
      const popups = { ...state.popups };
      const trackPopupIds = { ...state.trackPopupIds, [action.trackId]: ids };
      action.popups.forEach((popup) => {
        popups[popup.id] = popup;
      });

      return { ...state, trackPopupIds, popups };
    }
    case CREATE_POPUP: {
      const popup = action.popup;
      const popups = state.popups;
      popups[popup.id] = popup;

      const trackPopupIds = state.trackPopupIds;
      if (!trackPopupIds[popup.trackId]) {
        trackPopupIds[popup.trackId] = [];
      }
      trackPopupIds[action.trackId].push(popup.id);

      return { ...state, popups, trackPopupIds };
    }
    case DELETE_POPUP: {
      const trackPopupIds = state.trackPopupIds;
      trackPopupIds[action.trackId] = trackPopupIds[action.trackId].filter((id) => id !== action.popupId);
      const popups = state.popups;
      delete popups[action.popupId];
      return { ...state, popups, trackPopupIds };
    }
    default: {
      return state;
    }
  }
}

export const selectFeature = createFeatureSelector<State>('popup');

export const getPopup = (id: string) => {
  return createSelector(selectFeature, (state: State) => state.popups[id]);
};

export const getTrackPopups = (trackId: string) => {
  return createSelector(selectFeature, (state: State) => {
    return state.trackPopupIds[trackId]
      ? state.trackPopupIds[trackId].map((id) => state.popups[id]).sort((p1, p2) => p1.popTime - p2.popTime)
      : [];
  });
};
