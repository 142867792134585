import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AlbumApi } from '@/apis';
import { PAGE_SIZE } from '@/config';
import { PaginatedList } from '@/libs/paginated-list';
import { Album } from '@/models';
import { UploadService } from '@/services/upload.service';
import { AppState } from '@/store';
import { SetAlbums, ConcatAlbums, SetAlbum, DeleteAlbum } from '@/store/album.store';

@Injectable()
export class AlbumService {
  constructor(private store: Store<AppState>, private api: AlbumApi, private uploadService: UploadService) {}
  albumPage: PaginatedList<Album> = new PaginatedList<Album>(PAGE_SIZE);

  fetchAlbums(isRefresh: boolean = true): Observable<Album[]> {
    const page = isRefresh ? 1 : this.albumPage.page + 1;
    return this.api.fetchAlbums(page, PAGE_SIZE).pipe(
      tap((albums) => {
        if (isRefresh) {
          this.store.dispatch(new SetAlbums(albums));
        } else {
          this.store.dispatch(new ConcatAlbums(albums));
        }
        this.albumPage.page = page;
        this.albumPage.setPage(albums);
      })
    );
  }

  fetchAlbum(id: string): Observable<Album> {
    return this.api.fetchAlbum(id).pipe(tap((album) => this.store.dispatch(new SetAlbum(album))));
  }

  createAlbum(body: any): Observable<Album> {
    return this.api.createAlbum(body).pipe(tap((album) => this.store.dispatch(new SetAlbum(album))));
  }

  updateAlbum(id: string, params: any): Observable<Album> {
    return this.api.updateAlbum(id, params).pipe(tap((album) => this.store.dispatch(new SetAlbum(album))));
  }

  deleteAlbum(id: string): Observable<any> {
    return this.api.deleteAlbum(id).pipe(tap(() => this.store.dispatch(new DeleteAlbum(id))));
  }

  searchAlbums(params: any, isRefresh: boolean = true): Observable<Album[]> {
    const page = isRefresh ? 1 : this.albumPage.page + 1;
    return this.api.searchAlbums({ ...params, page, per_page: PAGE_SIZE }).pipe(
      tap((albums) => {
        if (isRefresh) {
          this.store.dispatch(new SetAlbums(albums));
        } else {
          this.store.dispatch(new ConcatAlbums(albums));
        }
        this.albumPage.page = page;
        this.albumPage.setPage(albums);
      })
    );
  }

  uploadCoverImage(file: File): Observable<any> {
    if (!file) {
      return;
    }

    const policy$ = this.api.issuePolicy(file.name, file.size, file.type);
    return this.uploadService.uploadImageUsingPolicy(file, policy$);
  }
}
