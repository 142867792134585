import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SpotAlbumApi } from '@/apis';
import { SpotAlbum } from '@/models';
import { AppState } from '@/store';
import { SetSpotAlbums, CreateSpotAlbum, DeleteSpotAlbum } from '@/store/spot-album.store';

@Injectable()
export class SpotAlbumService {
  constructor(private store: Store<AppState>, private api: SpotAlbumApi) {}

  fetchSpotAlbums(albumId: string): Observable<SpotAlbum[]> {
    return this.api
      .fetchSpotAlbums(albumId)
      .pipe(tap((spotAlbums) => this.store.dispatch(new SetSpotAlbums(albumId, spotAlbums))));
  }

  createSpotAlbum(spotId: string, params: any): Observable<SpotAlbum> {
    return this.api
      .createSpotAlbum(spotId, params)
      .pipe(tap((spotAlbum) => this.store.dispatch(new CreateSpotAlbum(spotAlbum))));
  }

  deleteSpotAlbum(spotAlbum: SpotAlbum): Observable<any> {
    const spotId = spotAlbum.spot.id;
    const id = spotAlbum.id;
    return this.api.deleteSpotAlbum(spotId, id).pipe(tap(() => this.store.dispatch(new DeleteSpotAlbum(id))));
  }
}
