import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FcmTopic } from '@/models';

export const SET_FCM_TOPICS = '[FcmTopic] SetFcmTopics';

export class SetFcmTopics implements Action {
  readonly type = SET_FCM_TOPICS;
  constructor(public fcmTopics: FcmTopic[]) {}
}

export type All = SetFcmTopics;

export interface State {
  fcmTopics: { [id: string]: FcmTopic };
  fcmTopicIds: string[];
}
export const initialState: State = {
  fcmTopics: {},
  fcmTopicIds: [],
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case SET_FCM_TOPICS: {
      const ids = action.fcmTopics.map((fcmTopic) => fcmTopic.id);
      const fcmTopics = { ...state.fcmTopics };
      action.fcmTopics.forEach((fcmTopic) => {
        fcmTopics[fcmTopic.id] = fcmTopic;
      });
      return { ...state, fcmTopicIds: ids, fcmTopics };
    }
    default: {
      return state;
    }
  }
}

export const selectFeature = createFeatureSelector<State>('fcmTopic');

export const getFcmTopics = createSelector(selectFeature, (state: State) => {
  return state.fcmTopicIds.map((id) => state.fcmTopics[id]);
});
