import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FcmTopic } from '@/models';

@Injectable()
export class FcmTopicApi {
  constructor(private http: HttpClient) {}

  fetchFcmTopics(): Observable<FcmTopic[]> {
    return this.http.get<FcmTopic[]>('fcm_topics');
  }
}
