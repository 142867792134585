import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { SessionService } from '@/services';

export interface Page {
  title: string;
  url: string;
  icon: string;
  routerLinkActiveOptions?: { exact: boolean };
}

export interface Handler {
  title: string;
  behavior: () => void;
  icon: string;
}

export interface PageGroup {
  title: string;
  pages: (Page | Handler)[];
}

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  appPages: PageGroup[] = [
    {
      title: 'コンテンツ管理',
      pages: [
        {
          title: 'コンテンツ',
          url: '/content',
          icon: './assets/icon/content.svg',
        },
        {
          title: 'スポット',
          url: '/spot',
          icon: './assets/icon/spot.svg',
        },
        {
          title: 'ナビゲータ',
          url: '/cast',
          icon: './assets/icon/cast.svg',
        },
        // {
        //   title: '投稿チラシ',
        //   url: '/flyer',
        //   icon: './assets/icon/flyer.svg',
        // },
      ],
    },
    // {
    //   title: 'お知らせ',
    //   pages: [
    //     {
    //       title: '原稿作成・予約',
    //       url: '/notification-delivery/form/new',
    //       icon: './assets/icon/info.svg',
    //     },
    //     {
    //       title: '配信履歴',
    //       url: '/notification-delivery',
    //       icon: './assets/icon/info.svg',
    //       routerLinkActiveOptions: { exact: true },
    //     },
    //   ],
    // },
    {
      title: 'アカウント',
      pages: [
        {
          title: '設定',
          url: '/account/settings',
          icon: './assets/icon/setting.svg',
        },
        {
          title: 'コンテンツ公開申請',
          behavior: () => {
            window.open('https://form.run/@webmaster-1591431902');
          },
          icon: './assets/icon/publish.svg',
        },
        {
          title: 'ログアウト',
          behavior: () => {
            this.logout();
          },
          icon: './assets/icon/logout.svg',
        },
      ],
    },
    // {
    //   title: 'その他',
    //   pages: [
    //     {
    //       title: 'ヘルプ',
    //       url: '/other/help',
    //       icon: './assets/icon/help.svg',
    //     },
    //   ],
    // },
  ];

  constructor(private sessionService: SessionService, private storage: Storage, private modalCtrl: ModalController) {}

  async ngOnInit() {
    // const doneTutorial = await this.storage.get('doneTutorial');
    // if (!doneTutorial) {
    //   const modal = await this.modalCtrl.create({
    //     component: TutorialPage,
    //   });
    //   await modal.present();
    //   this.storage.set('doneTutorial', true);
    // }
  }

  logout() {
    this.sessionService.trySignOut();
  }
}
