import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { FloorMapApi } from '@/apis';
import { FloorMap } from '@/models';
import { AppState } from '@/store';
import { SetAlbumFloorMaps, SetFloorMap, DeleteFloorMap } from '@/store/floor-map.store';

import { UploadService } from './upload.service';

@Injectable()
export class FloorMapService {
  constructor(private store: Store<AppState>, private api: FloorMapApi, private uploadService: UploadService) {}

  fetchFloorMaps(albumId: string): Observable<FloorMap[]> {
    return this.api
      .fetchFloorMaps(albumId)
      .pipe(tap((floorMaps) => this.store.dispatch(new SetAlbumFloorMaps(albumId, floorMaps))));
  }

  fetchFloorMap(id: string): Observable<FloorMap> {
    return this.api.fetchFloorMap(id).pipe(tap((floorMap) => this.store.dispatch(new SetFloorMap(floorMap))));
  }

  createFloorMap(body: any): Observable<FloorMap> {
    return this.api.createFloorMap(body).pipe(tap((floorMap) => this.store.dispatch(new SetFloorMap(floorMap))));
  }

  updateFloorMap(id: string, params: any): Observable<FloorMap> {
    return this.api.updateFloorMap(id, params).pipe(tap((floorMap) => this.store.dispatch(new SetFloorMap(floorMap))));
  }

  deleteFloorMap(id: string): Observable<any> {
    return this.api.deleteFloorMap(id).pipe(tap(() => this.store.dispatch(new DeleteFloorMap(id))));
  }

  uploadMapImage(file: File): Observable<any> {
    if (!file) {
      return;
    }

    const policy$ = this.api.issuePolicy(file.name, file.size, file.type);
    return this.uploadService.uploadImageUsingPolicy(file, policy$);
  }
}
