import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CastApi } from '@/apis';
import { PAGE_SIZE } from '@/config';
import { PaginatedList } from '@/libs/paginated-list';
import { Cast } from '@/models';
import { UploadService } from '@/services/upload.service';
import { AppState } from '@/store';
import { SetCasts, ConcatCasts, SetCast, DeleteCast } from '@/store/cast.store';

@Injectable()
export class CastService {
  constructor(private store: Store<AppState>, private api: CastApi, private uploadService: UploadService) {}
  castPage: PaginatedList<Cast> = new PaginatedList<Cast>(PAGE_SIZE);

  fetchCasts(isRefresh: boolean = true): Observable<Cast[]> {
    const page = isRefresh ? 1 : this.castPage.page + 1;
    return this.api.fetchCasts(page, PAGE_SIZE).pipe(
      tap((casts) => {
        if (isRefresh) {
          this.store.dispatch(new SetCasts(casts));
        } else {
          this.store.dispatch(new ConcatCasts(casts));
        }
        this.castPage.page = page;
        this.castPage.setPage(casts);
      })
    );
  }

  fetchCast(id: string): Observable<Cast> {
    return this.api.fetchCast(id).pipe(tap((cast) => this.store.dispatch(new SetCast(cast))));
  }

  createCast(body: any): Observable<Cast> {
    return this.api.createCast(body).pipe(tap((cast) => this.store.dispatch(new SetCast(cast))));
  }

  updateCast(id: string, params: any): Observable<Cast> {
    return this.api.updateCast(id, params).pipe(tap((cast) => this.store.dispatch(new SetCast(cast))));
  }

  deleteCast(id: string): Observable<any> {
    return this.api.deleteCast(id).pipe(tap(() => this.store.dispatch(new DeleteCast(id))));
  }

  searchCasts(params: any, isRefresh: boolean = true): Observable<Cast[]> {
    const page = isRefresh ? 1 : this.castPage.page + 1;
    return this.api.searchCasts({ ...params, page, per_page: PAGE_SIZE }).pipe(
      tap((casts) => {
        if (isRefresh) {
          this.store.dispatch(new SetCasts(casts));
        } else {
          this.store.dispatch(new ConcatCasts(casts));
        }
        this.castPage.page = page;
        this.castPage.setPage(casts);
      })
    );
  }

  uploadProfileImage(file: File): Observable<any> {
    if (!file) {
      return;
    }

    const policy$ = this.api.issuePolicy(file.name, file.size, file.type);
    return this.uploadService.uploadImageUsingPolicy(file, policy$);
  }
}
