import { NgModule } from '@angular/core';

import { EnumToHumanReadablePipe } from './enum-to-human-readable/enum-to-human-readable.pipe';
import { FilterAutocompleteOptionsPipe } from './filter-autocomplete-options/filter-autocomplete-options.pipe';
import { FormatDatePipe } from './format-date/format-date.pipe';
import { MicrosecToMinPipe } from './microsec-to-min/microsec-to-min.pipe';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { SecToMinPipe } from './sec-to-min/sec-to-min.pipe';
import { TrackByKeyPipe } from './track-by-key/track-by-key.pipe';

@NgModule({
  declarations: [
    SecToMinPipe,
    MicrosecToMinPipe,
    SafeHtmlPipe,
    EnumToHumanReadablePipe,
    FormatDatePipe,
    FilterAutocompleteOptionsPipe,
    TrackByKeyPipe,
  ],
  imports: [],
  exports: [
    SecToMinPipe,
    MicrosecToMinPipe,
    SafeHtmlPipe,
    EnumToHumanReadablePipe,
    FormatDatePipe,
    FilterAutocompleteOptionsPipe,
    TrackByKeyPipe,
  ],
})
export class PipesModule {}
