import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NotificationDelivery } from '@/models';

export const SET_NOTIFICATION_DELIVERIES = '[NotificationDelivery] SetNotificationDeliveries';
export const CONCAT_NOTIFICATION_DELIVERIES = '[NotificationDelivery] ConcatNotificationDeliveries';
export const SET_NOTIFICATION_DELIVERY = '[NotificationDelivery] SetNotificationDelivery';
export const DELETE_NOTIFICATION_DELIVERY = '[NotificationDelivery] DeleteNotificationDelivery';

export class SetNotificationDeliveries implements Action {
  readonly type = SET_NOTIFICATION_DELIVERIES;
  constructor(public notificationDeliveries: NotificationDelivery[]) {}
}
export class ConcatNotificationDeliveries implements Action {
  readonly type = CONCAT_NOTIFICATION_DELIVERIES;
  constructor(public notificationDeliveries: NotificationDelivery[]) {}
}
export class SetNotificationDelivery implements Action {
  readonly type = SET_NOTIFICATION_DELIVERY;
  constructor(public notificationDelivery: NotificationDelivery) {}
}
export class DeleteNotificationDelivery implements Action {
  readonly type = DELETE_NOTIFICATION_DELIVERY;
  constructor(public notificationDeliveryId: string) {}
}

export type All =
  | SetNotificationDeliveries
  | ConcatNotificationDeliveries
  | SetNotificationDelivery
  | DeleteNotificationDelivery;

export interface State {
  notificationDeliveries: { [id: string]: NotificationDelivery };
  notificationDeliveryIds: string[];
}
export const initialState: State = {
  notificationDeliveries: {},
  notificationDeliveryIds: [],
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case SET_NOTIFICATION_DELIVERIES: {
      const ids = action.notificationDeliveries.map((notificationDelivery) => notificationDelivery.id);
      const notificationDeliveries = { ...state.notificationDeliveries };
      action.notificationDeliveries.forEach((notificationDelivery) => {
        notificationDeliveries[notificationDelivery.id] = notificationDelivery;
      });
      return { ...state, notificationDeliveryIds: ids, notificationDeliveries };
    }
    case CONCAT_NOTIFICATION_DELIVERIES: {
      const ids = action.notificationDeliveries.map((album) => album.id);
      const notificationDeliveries = { ...state.notificationDeliveries };
      action.notificationDeliveries.forEach((album) => {
        notificationDeliveries[album.id] = album;
      });
      return { ...state, notificationDeliveryIds: state.notificationDeliveryIds.concat(ids), notificationDeliveries };
    }
    case SET_NOTIFICATION_DELIVERY: {
      const notificationDelivery = action.notificationDelivery;
      const notificationDeliveries = state.notificationDeliveries;
      notificationDeliveries[notificationDelivery.id] = notificationDelivery;
      const ids = notificationDeliveries[notificationDelivery.id]
        ? state.notificationDeliveryIds
        : [].concat(state.notificationDeliveryIds, notificationDelivery.id);
      return { ...state, notificationDeliveries, notificationDeliveryIds: ids };
    }
    case DELETE_NOTIFICATION_DELIVERY: {
      const ids = state.notificationDeliveryIds.filter((id) => id !== action.notificationDeliveryId);
      const notificationDeliveries = state.notificationDeliveries;
      delete notificationDeliveries[action.notificationDeliveryId];
      return { ...state, notificationDeliveries, notificationDeliveryIds: ids };
    }
    default: {
      return state;
    }
  }
}

export const selectFeature = createFeatureSelector<State>('notificationDelivery');

export const getNotificationDelivery = (id: string) => {
  return createSelector(selectFeature, (state: State) => state.notificationDeliveries[id]);
};

export const getNotificationDeliveries = createSelector(selectFeature, (state: State) => {
  return state.notificationDeliveryIds.map((id) => state.notificationDeliveries[id]);
});
