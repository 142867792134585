import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Track, Album } from '@/models';

@Injectable()
export class TrackApi {
  constructor(private http: HttpClient) {}

  fetchTracks(albumId: string): Observable<Track[]> {
    return this.http.get<Track[]>(`albums/${albumId}/tracks`);
  }

  fetchTrack(id: string): Observable<Track> {
    return this.http.get<Track>(`tracks/${id}`);
  }

  updateTracks(id: string, params: any): Observable<Album> {
    return this.http.put<Album>(`albums/${id}/tracks`, params);
  }

  issuePolicy(albumId: string, originalName: string, size: number, contentType: string): Observable<any> {
    const url = `albums/${albumId}/tracks/publish_policy`;
    return this.http.post(
      url,
      { track: { original_name: originalName, size, content_type: contentType } },
      { headers: new HttpHeaders().set('x-meta-respect-original-key-name', '') }
    );
  }
}
