import { ActionReducerMap } from '@ngrx/store';

import * as fromAlbum from './album.store';
import * as fromCast from './cast.store';
import * as fromComment from './comment.store';
import * as fromDiscountTicket from './discount-ticket.store';
import * as fromFcmTopic from './fcm-topic.store';
import * as fromFloormap from './floor-map.store';
import * as fromFlyer from './flyer.store';
import * as fromNotificationDelivery from './notification-delivery.store';
import * as fromPopup from './popup.store';
import * as fromSession from './session.store';
import * as fromSpotAlbum from './spot-album.store';
import * as fromSpot from './spot.store';
import * as fromTicketInformation from './ticket-information.store';
import * as fromUser from './user.store';

export interface AppState {
  user: fromUser.State;
  session: fromSession.State;
  album: fromAlbum.State;
  cast: fromCast.State;
  comment: fromComment.State;
  floorMap: fromFloormap.State;
  popup: fromPopup.State;
  spot: fromSpot.State;
  spotAlbum: fromSpotAlbum.State;
  notificationDelivery: fromNotificationDelivery.State;
  fcmTopic: fromFcmTopic.State;
  flyer: fromFlyer.State;
  discountTicket: fromDiscountTicket.State;
  ticketInformation: fromTicketInformation.State;
}

export const reducers: ActionReducerMap<AppState> = {
  user: fromUser.reducer,
  session: fromSession.reducer,
  album: fromAlbum.reducer,
  cast: fromCast.reducer,
  comment: fromComment.reducer,
  floorMap: fromFloormap.reducer,
  popup: fromPopup.reducer,
  spot: fromSpot.reducer,
  spotAlbum: fromSpotAlbum.reducer,
  notificationDelivery: fromNotificationDelivery.reducer,
  fcmTopic: fromFcmTopic.reducer,
  flyer: fromFlyer.reducer,
  discountTicket: fromDiscountTicket.reducer,
  ticketInformation: fromTicketInformation.reducer,
};

export { metaReducers } from './meta';
