import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UserApi } from '@/apis';
import { User } from '@/models';
import { AppState } from '@/store';
import { SetCurrentUser } from '@/store/user.store';

import { FormService } from './form.service';

@Injectable()
export class UserService {
  constructor(private store: Store<AppState>, private api: UserApi, private formService: FormService) {}

  updateProfile(params: any): Observable<User> {
    return this.api.updateProfile(params).pipe(tap((user) => this.store.dispatch(new SetCurrentUser(user))));
  }

  async updateEmail(email: string): Promise<Observable<any>> {
    return await this.formService.submit(this.api.updateEmail(email));
  }

  get(): Observable<User> {
    return this.api.get();
  }

  fetchCurrentUser(): Observable<User> {
    return this.api.fetchCurrentUser().pipe(tap((user) => this.store.dispatch(new SetCurrentUser(user))));
  }

  // deleteSavedAuthorization() {
  //   return this.storageSrv.remove(UUID_NAME).then(_ => {
  //     this.storageSrv.remove(PASSWORD_NAME);
  //   });
  // }

  initializeUser(body: any): Observable<User> {
    return this.api.initializeUser(body);
  }
}
