import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';

import { AutocompleteContentDirective } from './autocomplete-content/autocomplete-content.directive';
import { AutocompleteDirective } from './autocomplete/autocomplete.directive';
import { BgImgDirective } from './bg-img/bg-img.directive';

@NgModule({
  declarations: [BgImgDirective, AutocompleteDirective, AutocompleteContentDirective],
  imports: [OverlayModule],
  exports: [BgImgDirective, AutocompleteDirective, AutocompleteContentDirective],
})
export class DirectivesModule {}
