import { NgModule } from '@angular/core';

import { AlbumService } from './album.service';
import { CastService } from './cast.service';
import { CommentService } from './comment.service';
import { DiscountTicketService } from './discount-ticket.service';
import { EditorImageService } from './editor-image.service';
import { FcmTopicService } from './fcm-topic.service';
import { FloorMapService } from './floor-map.service';
import { FlyerService } from './flyer.service';
import { FormService } from './form.service';
import { MapMarkerService } from './map-marker.service';
import { NotificationDeliveryService } from './notification-delivery.service';
import { PopupService } from './popup.service';
import { SessionService } from './session.service';
import { SpotAlbumService } from './spot-album.service';
import { SpotService } from './spot.service';
import { TicketInformationService } from './ticket-information.service';
import { TrackService } from './track.service';
import { UploadService } from './upload.service';
import { UserService } from './user.service';

@NgModule({
  providers: [
    SessionService,
    UserService,
    FormService,
    AlbumService,
    CommentService,
    CastService,
    SpotService,
    SpotAlbumService,
    TrackService,
    PopupService,
    UploadService,
    FloorMapService,
    NotificationDeliveryService,
    FcmTopicService,
    MapMarkerService,
    FlyerService,
    DiscountTicketService,
    TicketInformationService,
    EditorImageService,
  ],
})
export class ServicesModule {}
