<div class="root">
  <ion-content class="nav">
    <div class="nav-logo">
      <img src="./assets/logo_dark.svg" alt="33Tab" />
    </div>
    <div *ngFor="let group of appPages; trackBy: 'title' | trackByKey" class="nav-group">
      <div class="nav-group-title">{{ group.title }}</div>
      <div class="nav-group-pages">
        <ng-container *ngFor="let page of group.pages; trackBy: 'title' | trackByKey">
          <ng-container *ngIf="page.url; else Handler">
            <a
              class="nav-group-pages-item"
              [routerLink]="page.url"
              routerLinkActive="current"
              [routerLinkActiveOptions]="page.routerLinkActiveOptions ? page.routerLinkActiveOptions : {}"
            >
              <ion-icon [src]="page.icon" class="icon"></ion-icon>
              <div class="text">{{ page.title }}</div>
            </a>
          </ng-container>
          <ng-template #Handler>
            <a class="nav-group-pages-item" (click)="page.behavior()">
              <ion-icon [src]="page.icon" class="icon"></ion-icon>
              <div class="text">{{ page.title }}</div>
            </a>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </ion-content>
  <div class="main">
    <ion-router-outlet></ion-router-outlet>
  </div>
</div>
