import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { User } from '@/models';

export const SET_CURRENT_USER = '[User] SetCurrentUser';
export const SELECTED_USER = '[User] SelectedUser';

export class SetCurrentUser implements Action {
  readonly type = SET_CURRENT_USER;
  constructor(public user: User) {}
}
export class SelectedUser implements Action {
  readonly type = SELECTED_USER;
  constructor(public user: User) {}
}

export type All = SetCurrentUser | SelectedUser;

export interface State {
  users: User[];
  currentUser: User;
  selectedUser: User;
}
export const initialState = {
  users: [],
  currentUser: null,
  selectedUser: null,
};

export const selectFeature = createFeatureSelector<State>('user');
export const getCurrentUser = createSelector(selectFeature, (state: State) => state.currentUser);

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case SET_CURRENT_USER:
      return { ...state, currentUser: action.user };
    case SELECTED_USER:
      return { ...state, selectedUser: action.user };
    default: {
      return state;
    }
  }
}
