import { Action } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Album, Track } from '@/models';

export const SET_ALBUMS = '[Album] SetAlbums';
export const CONCAT_ALBUMS = '[Album] ConcatAlbums';
export const SET_ALBUM = '[Album] SetAlbum';
export const DELETE_ALBUM = '[Album] DeleteAlbum';
export const SET_ALBUM_TRACKS = '[Album] SetTracks';

export class SetAlbums implements Action {
  readonly type = SET_ALBUMS;
  constructor(public albums: Album[]) {}
}
export class ConcatAlbums implements Action {
  readonly type = CONCAT_ALBUMS;
  constructor(public albums: Album[]) {}
}
export class SetAlbum implements Action {
  readonly type = SET_ALBUM;
  constructor(public album: Album) {}
}
export class DeleteAlbum implements Action {
  readonly type = DELETE_ALBUM;
  constructor(public albumId: string) {}
}
export class SetAlbumTracks implements Action {
  readonly type = SET_ALBUM_TRACKS;
  constructor(public albumId: string, public tracks: Track[]) {}
}

export type All = SetAlbums | ConcatAlbums | SetAlbum | DeleteAlbum | SetAlbumTracks;

export interface State {
  albums: { [id: string]: Album };
  tracks: { [id: string]: Track[] };
  albumIds: string[];
}
export const initialState: State = {
  albums: {},
  tracks: {},
  albumIds: [],
};

export function reducer(state: State = initialState, action: All): State {
  switch (action.type) {
    case SET_ALBUMS: {
      const ids = action.albums.map((album) => album.id);
      const albums = { ...state.albums };
      action.albums.forEach((album) => {
        albums[album.id] = album;
      });
      return { ...state, albumIds: ids, albums };
    }
    case CONCAT_ALBUMS: {
      const ids = action.albums.map((album) => album.id);
      const albums = { ...state.albums };
      action.albums.forEach((album) => {
        albums[album.id] = album;
      });
      return { ...state, albumIds: state.albumIds.concat(ids), albums };
    }
    case SET_ALBUM_TRACKS: {
      const tracks = state.tracks;
      tracks[action.albumId] = action.tracks;
      return { ...state, tracks };
    }
    case SET_ALBUM: {
      const album = action.album;
      const albums = state.albums;
      albums[album.id] = album;
      const ids = albums[album.id] ? state.albumIds : [].concat(state.albumIds, album.id);
      return { ...state, albums, albumIds: ids };
    }
    case DELETE_ALBUM: {
      const ids = state.albumIds.filter((id) => id !== action.albumId);
      const albums = state.albums;
      delete albums[action.albumId];
      return { ...state, albums, albumIds: ids };
    }
    default: {
      return state;
    }
  }
}

export const selectFeature = createFeatureSelector<State>('album');

export const getAlbum = (id: string) => {
  return createSelector(selectFeature, (state: State) => state.albums[id]);
};

export const getAlbums = createSelector(selectFeature, (state: State) => {
  return state.albumIds.map((id) => state.albums[id]);
});

export const getAlbumTracks = (albumId: string) => {
  return createSelector(selectFeature, (state: State) => state.tracks[albumId]);
};

export const getAlbumTracksWithoutSample = (albumId: string) => {
  return createSelector(selectFeature, (state: State) =>
    state.tracks[albumId] ? state.tracks[albumId].filter((track) => !track.sampleContent) : []
  );
};
